.mainPhotoDate{
    position:relative;
    .date{
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: #fff;
        padding: 6px 15px;
        letter-spacing: 1px;
        text-transform: uppercase;
    }
}