.lang {
  background-color: transparent;
  margin-right: 15px;

  &.show {
    button {
      background-color: rgba(71,71,71,.93) !important;
      border-color: #fff;
    }
  }

  button {
    background-color: transparent;
    font-size: 14px;
    border-color: #fff;

    @include on-event {
      background-color: rgba(71,71,71,.93);
      border-color: #fff;
    }
  }
  .dropdown-menu {
    min-width: 46px;

    a {
      display: block;
      color: #0f3959;
      padding: 4px 6px;
      font-size: 14px;

      @include on-event {
        text-decoration: none;
        background-color: lightgray;
      }
    }
  }
}

.headerSimple{
  .lang {
    color:#333333;

    button {
      border-color: #333333;
      color:#333333;
  
      @include on-event {
        background-color: transparent;
        border-color: #333333;
      }
    }
  }
}
