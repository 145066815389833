.specialForm {
  .titleSection {
    padding-bottom: 0;
  }

  h5 {
    padding-bottom: 25px;
    text-transform: uppercase;
    color: $darkBlue;
    font-size: 16px;
    text-transform: lowercase;
  }

  label {
    margin-bottom: 0;
    font-size: 12px;

    @include media(medium) {
      font-size: 13px;
    }

    @include media(xlarge) {
      font-size: 14px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .form-group {
    margin-bottom: 25px;
  }

  input[type="text"],
  input[type="email"],
  input[type="tel"],
  textarea {
    border-radius: 0 !important;
    border: 0;
    border-bottom: 1px solid #1d1d1b;
    color: #1d1d1b;
    background-color: transparent;
    height: auto;
    padding: 5px 5px 5px 0;
    transition: 0.3s;
    margin-bottom: 5px;
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 5px;

    &:focus {
      padding-left: 5px;
      box-shadow: none !important;
      border-color: #1d1d1b;
    }
  }

  .checkWrap {
    .form-group {
      margin-bottom: 7px;
    }
  }

  a {
    color: #000;
    text-transform: uppercase;
    text-decoration: underline;
  }

  .agree {
    display: flex;
    text-align: justify;
    font-size: 10px;

    @include media(medium) {
      font-size: 11px;
    }

    @include media(xlarge) {
      font-size: 11px;
    }

    p {
      font-size: 12px;

      @include media(medium) {
        font-size: 13px;
      }

      @include media(xlarge) {
        font-size: 14px;
      }
    }
  }

  .showFormPanel {
    padding-top: 8px;
    padding-bottom: 15px;

    .blueLine {
      width: 100%;
      height: 2px;
      background-color: #8e9ba4;
    }
    img {
      margin-top: -32px;
      position: relative;
      z-index: 3;

      &:hover {
        cursor: pointer;
      }
    }
    .showFormText {
      color: $blue;
      font-size: 13px;
      margin-top: -6px;
    }
  }

  .formWrap {
    display: none;
  }

  .otherInfo {
    p {
      color: $blue;
      font-size: 13px;
    }
  }

  span.wpcf7-not-valid-tip {
    font-size: 13px !important;
    margin-left: 0;
    padding-right: 15px;
    min-width: 150px;
    text-align: left;
  }

  div.wpcf7-validation-errors,
  div.wpcf7-acceptance-missing {
    border: 0 !important;
    text-align: center;
    color: #ff0000;
  }

  .form-check-input {
    margin-top: -11px;

    &:hover {
      cursor: pointer;
    }
  }

  .div.wpcf7-mail-sent-ok {
    color: #398f14;
    border: none;
    text-align: center;
  }
}

div.wpcf7-response-output {
  margin-top: 0 !important;
  margin-bottom: 30px !important;
}

div.wpcf7-validation-errors,
div.wpcf7-acceptance-missing {
  border: 0 !important;
  text-align: center;
  color: #ff0000;
}
