.offer {
  overflow: hidden;
  .item {
    // margin-bottom: 35px;

    .photoWrap {
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      min-height: 220px;

      @media (min-width: 450px) {
        min-height: 250px;
      }

      @media (min-width: 576px) {
        min-height: 350px;
      }

      @media (min-width: 1200px) {
        min-height: 400px;
      }

      @media (min-width: 1600px) {
        min-height: 450px;
      }

      @media (min-width: 1800px) {
        min-height: 500px;
      }
    }
    .textWrap {
      .textBox {
        max-width: 450px;
        margin: 0 auto;
        padding: 40px 15px 50px 15px;
        .titleSection {
          text-shadow: 2px 2px 7px #fff;
          text-align: left;
          padding-top: 0;
          padding-bottom: 20px;

          @media (min-width: 1200px) {
            line-height: 60px;
          }
        }

        p {
          text-align: justify;
        }
        .btnBox {
          padding-top: 30px;

          @include media(large) {
            padding-top: 50px;
          }
        }
      }
    }

    //item odd (nieparzysty)
    &:nth-of-type(odd) {
      .textWrap {
        .textBox {
        }
      }
    }

    //item even (parzysty)
    &:nth-of-type(even) {
      .textWrap {
        .textBox {
          .titleSection {
          }
        }
      }
    }
    // end item even (parzysty)
  }
}
