html {
  box-sizing: border-box;
  font-size: 16px;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body, h1, h2, h3, h4, h5, h6, p, ol, ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

ol, ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

body {
  transition: 0.1s;
}

@media (max-width: 576px) {
  section, .reservationPanelWrap {
    overflow-x: hidden !important;
  }
}

main.overSlider {
  position: relative;
  z-index: 10;
  background-color: #fff;
}

@media (min-width: 1050px) {
  main.overSlider {
    margin-top: 100vh;
  }
}

body.fadeIn {
  opacity: 1;
}

.page.noSlider {
  margin-top: 120px;
}

@media (min-width: 768px) {
  .page.noSlider {
    margin-top: 165px;
  }
}

@media (min-width: 992px) {
  .page.noSlider {
    margin-top: 180px;
  }
}

.titleBox {
  font-family: 'Montserrat';
  font-weight: bold;
  font-size: 15px;
  letter-spacing: .7px;
}

.contentTextWrap .textBox {
  line-height: 27px;
}

input.border-danger,
select.border-danger {
  border-color: #dc3545 !important;
  border: 1px solid #dc3545 !important;
}

.p-relative {
  position: relative;
}

.mainContainer {
  max-width: 1400px;
  margin: 0 auto;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .mainContainer {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media (min-width: 1200px) {
  .mainContainer {
    padding-left: 60px;
    padding-right: 60px;
  }
}

.mainContainer--full {
  max-width: inherit;
  width: 100%;
  max-width: 1700px;
}

a {
  color: #0f3959;
}

/* BACKGROUND */
.bgPrimary {
  background-color: #efece8;
}

.darkBlueBg {
  background-color: #0f3959;
}

p {
  font-size: 15px;
  font-weight: 300;
}

@media (min-width: 768px) {
  p {
    font-size: 16px;
  }
}

@media (min-width: 1200px) {
  p {
    font-size: 17px;
  }
}

.slide {
  width: 100%;
  height: 100%;
  position: relative;
}

.psuedo-background-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.orangeColor {
  color: #ec6615;
}

.orangeColor:hover, .orangeColor:active, .orangeColor:focus {
  color: #ec6615;
}

.lineSeparator {
  width: 100%;
  height: 1px;
  background-color: #888888;
  margin-top: 9px;
  margin-bottom: 7px;
}

.subTitle {
  color: #333333;
  text-transform: uppercase;
  font-size: 16px;
}

.titleSection {
  color: #333333;
  font-size: 25px;
  font-family: "GoudyOlStPL", sans-serif;
}

@media (min-width: 460px) {
  .titleSection {
    font-size: 26px;
  }
}

@media (min-width: 576px) {
  .titleSection {
    font-size: 28px;
  }
}

@media (min-width: 768px) {
  .titleSection {
    font-size: 30px;
  }
}

@media (min-width: 992px) {
  .titleSection {
    font-size: 32px;
  }
}

@media (min-width: 1200px) {
  .titleSection {
    font-size: 34px;
  }
}

.titleSection--medium {
  font-size: 23px;
}

@media (min-width: 460px) {
  .titleSection--medium {
    font-size: 24px;
  }
}

@media (min-width: 576px) {
  .titleSection--medium {
    font-size: 26px;
  }
}

@media (min-width: 768px) {
  .titleSection--medium {
    font-size: 28px;
  }
}

@media (min-width: 992px) {
  .titleSection--medium {
    font-size: 30px;
  }
}

@media (min-width: 1200px) {
  .titleSection--medium {
    font-size: 32px;
  }
}

.titleSection--small {
  font-family: "GoudyOlStPL", sans-serif;
  font-size: 21px;
}

@media (min-width: 460px) {
  .titleSection--small {
    font-size: 22px;
  }
}

@media (min-width: 576px) {
  .titleSection--small {
    font-size: 23px;
  }
}

@media (min-width: 768px) {
  .titleSection--small {
    font-size: 24px;
  }
}

@media (min-width: 992px) {
  .titleSection--small {
    font-size: 26px;
  }
}

@media (min-width: 1440px) {
  .titleSection--small {
    font-size: 28px;
  }
}

.titleSection--xsmall {
  font-size: 20px;
}

@media (min-width: 460px) {
  .titleSection--xsmall {
    font-size: 20px;
  }
}

@media (min-width: 768px) {
  .titleSection--xsmall {
    font-size: 22px;
  }
}

.titleSection--black {
  color: black;
}

.titleSection--bold {
  font-weight: bold;
}

.owl-item.active.center {
  transform: scale(1.1, 1.2);
}

.owl-carousel .owl-nav.disabled {
  display: none !important;
}

div.wpcf7 .wpcf7-submit:disabled {
  opacity: 0.7;
}

a {
  color: #ec6615;
}

a:hover, a:active, a:focus {
  color: #ec6615;
}

button:focus {
  outline: none;
}

@font-face {
  font-family: 'Open Sans';
  src: url("../fonts/OpenSans-Light.woff2") format("woff2"), url("../fonts/OpenSans-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'GoudyOlStPL';
  src: url("../fonts/GoudyOlStPL-BoldItalic.woff2") format("woff2"), url("../fonts/GoudyOlStPL-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'GoudyOlStPL';
  src: url("../fonts/GoudyOlStPL-Italic.woff2") format("woff2"), url("../fonts/GoudyOlStPL-Italic.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url("../fonts/Montserrat-Light.woff2") format("woff2"), url("../fonts/Montserrat-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url("../fonts/Montserrat-Regular.woff2") format("woff2"), url("../fonts/Montserrat-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
}

h5 {
  font-size: 38px;
}

.sliderBtn {
  font-size: 11px;
  background-color: transparent;
  border: 1px solid #fff;
  margin: 20px auto 0 auto;
  color: #fff;
  font-weight: bold;
  padding: 4px 7px;
  position: relative;
  z-index: 10;
  transition: 0.3s;
}

.sliderBtn:active, .sliderBtn:hover {
  background-color: #0f3959;
  color: #fff;
  text-decoration: none;
}

@media (min-width: 768px) {
  .sliderBtn {
    font-size: 13px;
    padding: 5px 10px;
  }
}

.moreBtn {
  background-color: transparent;
  background-size: contain;
  border: 0;
  color: #0f3959;
  width: auto;
  min-width: 110px;
  display: inline-block;
  padding: 11px 10px;
  font-size: 18px;
  background-image: url(../images/btn_arrow.png);
  background-repeat: no-repeat;
  background-position-x: 0;
  background-position-y: bottom;
  font-family: Shadows Into Light Two,cursive;
  font-weight: 400;
  animation-duration: .6s;
  animation-timing-function: ease-out;
}

.moreBtn:hover, .moreBtn:active, .moreBtn:focus {
  color: #0f3959;
  text-decoration: none;
  animation-name: moreBtnArrow;
  animation-fill-mode: forwards;
}

.moreBtn--white {
  color: #fff;
  background-image: url(../images/btn_arrow_white.png);
}

.moreBtn--white:hover, .moreBtn--white:active, .moreBtn--white:focus {
  color: #fff;
}

.primaryBtn {
  text-transform: uppercase;
  border: 1px solid #333333;
  color: #333333;
  font-size: 13px;
  background-color: #fff;
  padding: 8px 20px;
  font-weight: 500;
  letter-spacing: 1px;
  min-width: 180px;
  text-align: center;
}

@media (min-width: 1400px) {
  .primaryBtn {
    padding: 10px 22px;
  }
}

.primaryBtn:hover, .primaryBtn:active, .primaryBtn:focus {
  color: #333333;
  text-decoration: none;
}

.moreArticleLink {
  color: #333333;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
}

.moreArticleLink:hover, .moreArticleLink:active, .moreArticleLink:focus {
  color: #333333;
}

.moreArticleLink--white {
  color: #fff;
}

.moreArticleLink--white:hover, .moreArticleLink--white:active, .moreArticleLink--white:focus {
  color: #fff;
}

.bookBtn {
  font-weight: bold;
  background-color: #fff;
  border-radius: 0;
  color: #000;
  border: 1px solid black;
  font-family: "Lato", sans-serif !important;
  font-size: 14px;
  text-transform: uppercase;
  margin: 15px auto 15px auto !important;
  width: 200px;
  padding: 20px 15px;
}

.blueBtn {
  background-color: #0f3959;
  border: 1px solid #fff;
  text-transform: uppercase;
  font-size: 13px;
  color: #fff;
  padding: 6px 20px;
  margin: 5px;
}

.blueBtn:hover, .blueBtn:active, .blueBtn:focus {
  color: #fff;
  text-decoration: none;
}

@media (min-width: 576px) {
  .blueBtn {
    font-size: 14px;
  }
}

@media (min-width: 992px) {
  .blueBtn {
    font-size: 14px;
  }
}

@keyframes moreBtnArrow {
  0% {
    background-position-x: -140px;
  }
  100% {
    background-position-x: 0;
  }
}

.header {
  transition: 0.35s;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 30;
  padding: 0;
}

@media (min-width: 992px) {
  .header {
    background-color: transparent;
  }
}

.header .headerTop {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 576px) {
  .header .headerTop {
    justify-content: space-between;
  }
}

.header .headerTop .leftPanel {
  display: flex;
}

@media (min-width: 576px) {
  .header .headerTop .leftPanel {
    display: flex;
  }
}

.header .headerTop .rightPanel .socialIcons {
  display: none;
}

@media (min-width: 768px) {
  .header .headerTop .rightPanel .socialIcons {
    display: block;
  }
}

.header .navbar-brand__logo {
  width: 120px;
  height: auto;
  transition: .3s;
}

@media (min-width: 576px) {
  .header .navbar-brand__logo {
    width: 125px;
  }
}

@media (min-width: 768px) {
  .header .navbar-brand__logo {
    width: 135px;
  }
}

@media (min-width: 992px) {
  .header .navbar-brand__logo {
    width: 135px;
  }
}

@media (min-width: 992px) {
  .header .navbar-brand__logo {
    width: 140px;
  }
}

@media (min-width: 1400px) {
  .header .navbar-brand__logo {
    width: 155px;
  }
}

.group {
  background-color: #dddddd;
  padding-top: 90px;
  padding-bottom: 130px;
}

.group--specialOffer {
  background-color: #e6e6e6;
}

.groupSlider {
  background-color: #fff;
  margin-top: 25px;
  -webkit-box-shadow: 0px 0px 46px -20px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 46px -20px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 46px -20px rgba(0, 0, 0, 0.75);
}

.groupSlider .item article {
  padding: 30px;
  background-color: #fff;
}

.groupSlider .item article h5 {
  font-size: 24px;
  font-family: "GoudyOlStPL", sans-serif;
}

.groupSlider .item article .text {
  padding-top: 15px;
  padding-bottom: 15px;
}

.groupSlider .item article .text p {
  font-size: 12px;
}

@media (max-width: 768px) {
  .groupSlider .item article .photoBox {
    margin: 25px 0;
  }
}

.groupSlider .item article .photoBox img {
  width: auto;
}

.groupSlider .item + .item {
  border-left: 2px solid #e7e7e7;
}

.groupSlider .owl-item + .owl-item {
  border-left: 2px solid #e7e7e7;
}

.groupSlider.owl-theme .owl-nav {
  width: 100%;
  max-width: 1630px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: space-between;
  height: 0;
  margin-top: -40px;
}

.groupSlider.owl-carousel .owl-nav button.owl-prev,
.groupSlider.owl-carousel .owl-nav button.owl-next {
  width: 15px;
  height: 17px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin: 20px 5px;
}

@media (min-width: 576px) {
  .groupSlider.owl-carousel .owl-nav button.owl-prev,
  .groupSlider.owl-carousel .owl-nav button.owl-next {
    margin: 20px;
  }
}

@media (min-width: 992px) {
  .groupSlider.owl-carousel .owl-nav button.owl-prev,
  .groupSlider.owl-carousel .owl-nav button.owl-next {
    width: 24px;
    height: 44px;
  }
}

.groupSlider.owl-carousel .owl-nav button.owl-prev:hover,
.groupSlider.owl-carousel .owl-nav button.owl-next:hover {
  background-color: transparent;
}

.groupSlider.owl-carousel .owl-nav button.owl-prev {
  background-image: url("../images/owl-left-gray.png");
  margin-left: -50px;
}

.groupSlider.owl-carousel .owl-nav button.owl-next {
  background-image: url("../images/owl-right-gray.png");
  margin-right: -50px;
}

.logosSlider {
  padding: 10px 30px;
  background-color: #474747;
}

@media (min-width: 768px) {
  .logosSlider {
    padding: 10px 50px;
  }
}

@media (min-width: 1200px) {
  .logosSlider {
    padding: 10px 50px;
  }
}

.logosSlider .item {
  width: 155px;
  height: 60px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logosSlider .item img {
  width: auto;
  transform: scale(0.6);
}

@media (min-width: 768px) {
  .logosSlider .item {
    width: 200px;
    height: 80px;
  }
}

.ourHotels {
  background-color: #fff;
  padding-top: 0;
  padding-bottom: 0;
}

.ourHotels .nav {
  border: 0;
  overflow: hidden;
}

.ourHotels .nav li {
  width: 50%;
}

.ourHotels .nav li a {
  transition: .2s;
  display: block;
  padding: 15px 20px;
  border: 2px solid transparent;
  text-align: center;
  position: relative;
}

.ourHotels .nav li a:not(.active) {
  background-color: #f1f1f1;
}

.ourHotels .nav li a:hover, .ourHotels .nav li a:active, .ourHotels .nav li a:focus {
  text-decoration: none;
}

.ourHotels .nav li:nth-child(1) a:after {
  display: block;
  content: '';
  width: 30px;
  height: 100%;
  position: absolute;
  top: -2px;
  right: -38px;
  width: 0;
  height: 0;
  border-color: #f1f1f1 transparent transparent;
  border-style: solid;
  border-width: 103px 35px 0;
  z-index: 5;
}

.ourHotels .nav li .titleSection {
  color: #333333;
}

.ourHotels .nav li h5 {
  text-transform: uppercase;
  font-size: 14px;
  color: #333333;
}

.ourHotels .tab-content {
  padding: 30px 20px;
}

.ourHotels .tab-content a {
  color: #ec6615;
}

.ourHotels .tab-content .item {
  padding: 3px 5px;
  text-align: center;
}

.ourHotels .tab-content .item p {
  color: #333333;
  font-size: 14px;
}

@media (min-width: 1400px) {
  .ourHotels .tab-content .item p {
    font-size: 14px;
  }
}

.ourHotels .tab-content .item.big p {
  font-size: 15px;
}

@media (min-width: 1400px) {
  .ourHotels .tab-content .item.big p {
    font-size: 16px;
  }
}

.ourHotels .tab-content .wrap {
  max-width: 430px;
  margin: 0 auto;
}

.ourHotels .tab-content #ourGroup .item p {
  margin-top: 6px;
}

.newsHome {
  background-color: #919191;
  color: #fff;
  padding-top: 50px;
  padding-bottom: 70px;
}

.newsHome .titleSection {
  color: #fff;
}

.newsHome .lineSeparator {
  background-color: #fff;
}

.newsHome .subTitle {
  color: #fff;
}

.newsHome .primaryBtn {
  background-color: transparent;
  border-color: #fff;
  color: #fff;
}

.newsSlider {
  margin-top: 20px;
}

.newsSlider .item article {
  padding: 0 30px;
}

@media (min-width: 992px) {
  .newsSlider .item article {
    padding: 0 100px;
  }
}

.newsSlider .item article h5.date {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  padding: 20px 0 25px 0;
}

.newsSlider .item article h5.title {
  font-size: 24px;
  font-family: "GoudyOlStPL", sans-serif;
  color: #dedede;
}

.newsSlider .item article .text {
  padding-top: 25px;
  padding-bottom: 25px;
}

.newsSlider .item article .text p {
  font-size: 14px;
}

.newsSlider .item article .photoBox {
  text-align: center;
}

@media (max-width: 768px) {
  .newsSlider .item article .photoBox {
    margin: 25px 0;
  }
}

.newsSlider .item article .photoBox img {
  width: auto;
  margin: 0 auto;
}

.newsSlider.owl-theme .owl-nav {
  width: 100%;
  max-width: 1630px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: space-between;
  height: 0;
  margin-top: -40px;
}

.newsSlider.owl-carousel .owl-nav button.owl-prev,
.newsSlider.owl-carousel .owl-nav button.owl-next {
  width: 15px;
  height: 17px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin: 20px 5px;
}

@media (min-width: 576px) {
  .newsSlider.owl-carousel .owl-nav button.owl-prev,
  .newsSlider.owl-carousel .owl-nav button.owl-next {
    margin: 20px;
  }
}

@media (min-width: 992px) {
  .newsSlider.owl-carousel .owl-nav button.owl-prev,
  .newsSlider.owl-carousel .owl-nav button.owl-next {
    width: 24px;
    height: 44px;
  }
}

.newsSlider.owl-carousel .owl-nav button.owl-prev:hover,
.newsSlider.owl-carousel .owl-nav button.owl-next:hover {
  background-color: transparent;
}

.newsSlider.owl-carousel .owl-nav button.owl-prev {
  background-image: url("../images/owl-left-gray.png");
  margin-left: -50px;
}

.newsSlider.owl-carousel .owl-nav button.owl-next {
  background-image: url("../images/owl-right-gray.png");
  margin-right: -50px;
}

.promoInfo {
  position: absolute;
  background-color: #ec6615;
  color: #fff;
  right: 0;
  bottom: 120px;
  z-index: 3;
  border-left: 1px solid #8a4829;
  padding: 13px 18px;
  transition: .4;
}

@media (min-width: 768px) {
  .promoInfo {
    bottom: 225px;
  }
}

.promoInfo .icon {
  padding-right: 12px;
  border-right: 1px solid #f6b38a;
}

.promoInfo .text {
  padding-left: 12px;
  line-height: 19px;
  font-family: 'GoudyOlStPL';
}

.specialScroll {
  padding-top: 0;
  padding-bottom: 0;
  overflow: unset;
}

.specialScroll .item {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.specialScroll .item:nth-child(even) {
  background-color: #e8e8e8;
}

.specialScroll .item .content {
  width: 500px;
  margin-left: auto;
}

.specialScroll .item .content .titleSection {
  border-bottom: 2px solid #cccccc;
  padding-bottom: 7px;
}

.specialScroll .item .content .subTitle {
  padding-top: 5px;
  padding-bottom: 70px;
}

.specialScroll .item .content .text {
  max-width: 428px;
  margin: 60px auto;
  text-align: right;
  letter-spacing: 1px;
  line-height: 28px;
  padding-right: 83px;
}

.specialScroll .photoWrap {
  height: 100%;
  position: relative;
}

.specialScroll .photoWrap .photo {
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-position: center;
  position: sticky;
  top: 0;
  background-image: url("../images/scroll1.jpg");
}

header .rightPanel .socialIcons .darkSocialIcon {
  display: none;
}

header.headerSimple .rightPanel .socialIcons img {
  display: none;
}

header.headerSimple .rightPanel .socialIcons .darkSocialIcon {
  display: inline-block;
}

.specialScrollMobile {
  padding-top: 0;
  padding-bottom: 0;
  overflow: unset;
}

.specialScrollMobile .item {
  height: 70vh;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.specialScrollMobile .item:nth-child(even) {
  background-color: #e8e8e8;
}

.specialScrollMobile .item .content {
  margin-left: auto;
}

.specialScrollMobile .item .content .titleSection {
  border-bottom: 2px solid #cccccc;
  padding-bottom: 7px;
}

.specialScrollMobile .item .content .subTitle {
  padding-top: 5px;
  padding-bottom: 70px;
}

.specialScrollMobile .item .content .text {
  max-width: 428px;
  margin: 0 auto;
  text-align: right;
  letter-spacing: 1px;
  line-height: 28px;
}

.specialScrollMobile .photoWrap .photo {
  height: 70vh;
  width: 100%;
  background-size: cover;
  background-position: center;
  position: static;
  top: 0;
  background-image: url("../images/scroll1.jpg");
}

.specialScrollMobile .photoWrap .photo--1 {
  background-image: url("../images/scroll1.jpg");
}

.specialScrollMobile .photoWrap .photo--2 {
  background-image: url("../images/scroll2.jpg");
}

.specialScrollMobile .photoWrap .photo--3 {
  background-image: url("../images/scroll3.jpg");
}

.specialScrollMobile .photoWrap .photo--4 {
  background-image: url("../images/scroll4.jpg");
}

.reservationPanelWrap {
  display: block;
  bottom: 50px;
  right: 0;
  width: 100%;
  z-index: 20;
  background-color: rgba(71, 71, 71, 0.93);
  transition: .3s;
  position: static;
}

@media (min-width: 992px) {
  .reservationPanelWrap {
    position: fixed;
  }
}

.reservationPanelWrap .reservationPanel {
  z-index: 20;
  position: relative;
  padding: 20px 20px 10px 15px;
  margin: 0 !important;
}

@media (min-width: 576px) {
  .reservationPanelWrap .reservationPanel {
    display: block;
  }
}

@media (min-width: 1200px) {
  .reservationPanelWrap .reservationPanel {
    margin-top: -200px;
    padding: 20px 0 10px 0;
  }
}

.reservationPanelWrap .reservationPanel h5 {
  color: #0f3959;
  letter-spacing: 0.3px;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  text-transform: uppercase;
  width: 100%;
  color: #fff;
  border-bottom: 1px solid #fff;
  padding-bottom: 10px;
  margin-bottom: 0;
  text-align: left;
  font-size: 13px;
}

@media (min-width: 1400px) {
  .reservationPanelWrap .reservationPanel h5 {
    font-size: 14px;
  }
}

.reservationPanelWrap .reservationPanel .-form {
  justify-content: center;
}

@media (min-width: 768px) {
  .reservationPanelWrap .reservationPanel .-form {
    justify-content: space-between;
  }
}

.reservationPanelWrap .reservationPanel .-form .form-group {
  width: 100%;
}

@media (min-width: 576px) {
  .reservationPanelWrap .reservationPanel .-form .form-group {
    width: 50%;
  }
}

@media (min-width: 992px) {
  .reservationPanelWrap .reservationPanel .-form .form-group {
    width: auto;
  }
}

.reservationPanelWrap .reservationPanel .-form .form-group input,
.reservationPanelWrap .reservationPanel .-form .form-group select {
  margin: 0 5px;
  background-color: transparent;
  color: #fff !important;
  outline: 0 !important;
  box-shadow: none !important;
  border-bottom: 1px solid #fff !important;
  color: #000;
  font-size: 13px;
  border-radius: 0;
  padding: 13px 10px 10px 55px;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 300;
  margin-right: 20px;
  text-transform: uppercase;
  cursor: pointer;
  min-height: 47px;
  border: 0;
  margin: 0px 5px 0 5px;
  width: 100%;
}

@media (min-width: 992px) {
  .reservationPanelWrap .reservationPanel .-form .form-group input,
  .reservationPanelWrap .reservationPanel .-form .form-group select {
    width: auto;
    padding: 13px 10px 10px 45px;
  }
  .reservationPanelWrap .reservationPanel .-form .form-group input.inputDateIn, .reservationPanelWrap .reservationPanel .-form .form-group input.inputDateOut,
  .reservationPanelWrap .reservationPanel .-form .form-group select.inputDateIn,
  .reservationPanelWrap .reservationPanel .-form .form-group select.inputDateOut {
    width: 150px;
  }
}

@media (min-width: 1200px) {
  .reservationPanelWrap .reservationPanel .-form .form-group input,
  .reservationPanelWrap .reservationPanel .-form .form-group select {
    font-size: 13px;
  }
  .reservationPanelWrap .reservationPanel .-form .form-group input.inputDateIn, .reservationPanelWrap .reservationPanel .-form .form-group input.inputDateOut,
  .reservationPanelWrap .reservationPanel .-form .form-group select.inputDateIn,
  .reservationPanelWrap .reservationPanel .-form .form-group select.inputDateOut {
    width: 170px;
  }
}

@media (min-width: 1400px) {
  .reservationPanelWrap .reservationPanel .-form .form-group input,
  .reservationPanelWrap .reservationPanel .-form .form-group select {
    font-size: 13px;
  }
  .reservationPanelWrap .reservationPanel .-form .form-group input.inputDateIn, .reservationPanelWrap .reservationPanel .-form .form-group input.inputDateOut,
  .reservationPanelWrap .reservationPanel .-form .form-group select.inputDateIn,
  .reservationPanelWrap .reservationPanel .-form .form-group select.inputDateOut {
    width: auto;
  }
}

.reservationPanelWrap .reservationPanel .-form .form-group input:focus,
.reservationPanelWrap .reservationPanel .-form .form-group select:focus {
  color: #fff;
  border: 0;
  outline: 0 !important;
  box-shadow: none !important;
}

.reservationPanelWrap .reservationPanel .-form .form-group input.inputDateIn,
.reservationPanelWrap .reservationPanel .-form .form-group select.inputDateIn {
  background-image: url("../images/inputDateIn_bg.png");
  background-repeat: no-repeat;
  background-position: 4% center;
}

.reservationPanelWrap .reservationPanel .-form .form-group input.inputDateOut,
.reservationPanelWrap .reservationPanel .-form .form-group select.inputDateOut {
  background-image: url("../images/inputDateOut_bg.png");
  background-repeat: no-repeat;
  background-position: 4% center;
}

.reservationPanelWrap .reservationPanel .-form .form-group input.apartSelect,
.reservationPanelWrap .reservationPanel .-form .form-group select.apartSelect {
  background-image: url("../images/selectHotel_bg.png");
  background-repeat: no-repeat;
  background-position: 4% center;
}

@media (min-width: 992px) {
  .reservationPanelWrap .reservationPanel .-form .form-group input.apartSelect,
  .reservationPanelWrap .reservationPanel .-form .form-group select.apartSelect {
    width: 190px;
  }
}

@media (min-width: 1200px) {
  .reservationPanelWrap .reservationPanel .-form .form-group input.apartSelect,
  .reservationPanelWrap .reservationPanel .-form .form-group select.apartSelect {
    width: 215px;
  }
}

.reservationPanelWrap .reservationPanel .-form .form-group input.promocode,
.reservationPanelWrap .reservationPanel .-form .form-group select.promocode {
  background-image: url("../images/promoCode_bg.png");
  background-repeat: no-repeat;
  background-position: 4% center;
  padding: 13px 10px 10px 55px;
}

@media (min-width: 992px) {
  .reservationPanelWrap .reservationPanel .-form .form-group input.promocode,
  .reservationPanelWrap .reservationPanel .-form .form-group select.promocode {
    padding: 13px 10px 10px 50px;
  }
}

.reservationPanelWrap .reservationPanel .-form select option {
  padding: 5px;
  color: #000;
}

.reservationPanelWrap .reservationPanel .-form button {
  background-color: #ec6615;
  display: block !important;
  border-radius: 0;
  transition: .3s;
  color: #fff;
  border: 1px solid #c05b1d;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 300;
  font-size: 14px;
  text-transform: uppercase;
  margin: 10px auto 15px auto !important;
  letter-spacing: 3px;
  padding: 10px 30px;
}

@media (min-width: 992px) {
  .reservationPanelWrap .reservationPanel .-form button {
    width: 185px;
    padding: 10px 30px;
  }
}

@media (min-width: 1200px) {
  .reservationPanelWrap .reservationPanel .-form button {
    padding: 14px;
    width: auto;
  }
}

@media (min-width: 1250px) {
  .reservationPanelWrap .reservationPanel .-form button {
    padding: 10px 25px;
  }
}

@media (min-width: 1400px) {
  .reservationPanelWrap .reservationPanel .-form button {
    padding: 10px 35px;
  }
}

@media (min-width: 1600px) {
  .reservationPanelWrap .reservationPanel .-form button {
    padding: 10px 20px;
    margin-left: 25px !important;
    font-size: 14px;
  }
}

@media (min-width: 1800px) {
  .reservationPanelWrap .reservationPanel .-form button {
    padding: 10px 30px;
  }
}

.reservationPanelWrap .reservationPanel input::-webkit-input-placeholder, .reservationPanelWrap .reservationPanel textarea::-webkit-input-placeholder {
  color: #fff;
}

.reservationPanelWrap .reservationPanel input:-moz-placeholder, .reservationPanelWrap .reservationPanel textarea:-moz-placeholder {
  color: #fff;
}

@media (max-width: 1160px) {
  .reservationPanelWrap .reservationPanel .btnWrap {
    margin-left: auto;
    margin-right: auto;
  }
}

/* CALENDAR > DATEINPUT*/
/* EVENTS CALENDAR */
div.ba-calendar {
  background-color: #c1c0b8;
  border: 5px solid #555;
  color: #666666;
  display: block;
  margin: 0 0 20px 0;
  padding: 0;
  position: relative;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  width: 240px;
}

div.ba-calendar.fixed {
  position: fixed;
  top: 150px;
  z-index: 50;
}

div.ba-calendar table {
  background-color: #ede6df !important;
  border: 1px solid #c1c0b8 !important;
  border-collapse: separate;
  margin: 0;
  width: 100%;
}

div.ba-calendar table th {
  background-color: #819097;
  border: 1px solid #819097 !important;
  border-bottom: 1px solid #c1c0b8 !important;
  color: #fff;
  font-size: 18px;
  line-height: 20px;
  margin: 0;
  padding: 10px 0;
  text-align: center;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
  text-transform: none;
}

div.ba-calendar table td {
  background-color: #f5f6f7 !important;
  border: 1px solid #f5f6f7 !important;
  border-left: 1px solid #f5f6f7 !important;
  border-top: 1px solid #f5f6f7 !important;
  color: #c1c0b8 !important;
  cursor: default !important;
  font-size: 12px;
  line-height: 30px;
  padding: 0;
  text-align: center;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
}

div.ba-calendar table td.calendarDayHeading {
  background-color: #819097 !important;
  border: 1px solid #819097 !important;
  color: #ede6df !important;
  height: 10px;
  padding: 0;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
}

div.ba-calendar table td.calendarCell {
  background-color: #c1c0b8 !important;
  border: 1px solid #819097 !important;
  border-left: 1px solid #f5f6f7 !important;
  border-top: 1px solid #f5f6f7 !important;
  color: #555 !important;
  width: 14.28%;
}

div.ba-calendar table td.calendarCell.-with-event {
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%) !important;
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255, 255, 255, 0.05)), color-stop(100%, rgba(0, 0, 0, 0.05))) !important;
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%) !important;
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, rgba(255, 255, 255, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%) !important;
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, rgba(255, 255, 255, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%) !important;
  /* IE10+ */
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%) !important;
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#26ffffff', endColorstr='#26000000',GradientType=0 ) !important;
  /* IE6-9 */
  background-color: #a38461 !important;
  border: 1px solid #a38461 !important;
  border-left: 1px solid #806341 !important;
  border-top: 1px solid #806341 !important;
  color: #ede6df !important;
  text-shadow: 0 1px 0 #806341 !important;
}

div.ba-calendar table td.calendarCell.-with-event a {
  color: #ede6df !important;
  display: block;
  text-decoration: none;
}

div.ba-calendar table td.calendarCell.-with-event a:hover {
  color: #fff !important;
}

div.ba-calendar table td.calendarToday {
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%) !important;
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255, 255, 255, 0.05)), color-stop(100%, rgba(0, 0, 0, 0.05))) !important;
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%) !important;
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, rgba(255, 255, 255, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%) !important;
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, rgba(255, 255, 255, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%) !important;
  /* IE10+ */
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%) !important;
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#26ffffff', endColorstr='#26000000',GradientType=0 ) !important;
  /* IE6-9 */
  background-color: #555 !important;
  border: 1px solid #555 !important;
  border-left: 1px solid #2d373b !important;
  border-top: 1px solid #2d373b !important;
  color: #c1c0b8 !important;
  text-shadow: 0 1px 0 #2d373b;
}

div.ba-calendar table td.calendarToday a {
  color: #f5f6f7 !important;
  display: block;
  text-decoration: none;
}

div.ba-calendar table td.calendarToday a:hover {
  color: #fff !important;
}

/* calendar root element */
#calroot {
  /* place on top of other elements. set a higher value if nessessary */
  background-color: #045b38;
  border: 5px solid #045b38;
  color: #666666;
  display: none;
  margin: -15px 0 0 0;
  padding: 1px;
  position: absolute;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  top: 290px;
  width: 252px;
  z-index: 20000;
}

body.ba-tpl-index #calroot {
  position: fixed !important;
}

/* head. contains title, prev/next month controls and possible month/year selectors */
#calhead {
  background-color: #045b38;
  height: 40px;
  padding: 10px 0px 0px 0px;
}

#caltitle {
  color: #fff;
  float: left;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
  width: 200px;
}

#calnext,
#calprev,
.calnext,
.calprev {
  background-image: url("../images/left-arrow.png");
  background-position: 0px 0px;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  display: block;
  float: left;
  height: 25px;
  margin: 0 0 0 10px;
  width: 11px;
}

#calnext,
.calnext {
  background-image: url("../images/right-arrow.png");
  background-position: center !important;
  margin: -20px 10px 0 0;
  width: 15px;
  height: 20px;
  float: right;
}

#calprev,
.calprev {
  background-position: center !important;
  width: 15px;
  height: 20px;
}

#calprev:hover,
.calprev:hover {
  background-position: 0px -25px;
}

#calnext:hover,
.calnext:hover {
  background-position: 0px -75px;
}

#calprev.caldisabled,
#calnext.caldisabled {
  visibility: hidden;
}

/* year/month selector */
#caltitle select {
  font-size: 10px;
}

/* names of the days */
#caldays {
  /*background-color: #333;*/
  border-top: 1px solid #ccc;
  color: #fff;
  font-size: 12px;
  height: 15px;
  padding: 10px 0px 5px 0px;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
}

#caldays span {
  display: block;
  float: left;
  font-size: 12px;
  text-align: center;
  width: 36px;
}

/* container for weeks */
#calweeks {
  margin-top: 0px;
}

/* single week */
.calweek {
  clear: left;
  height: 25px;
}

/* single day */
.calweek a {
  background-color: #cbc7be;
  border: 1px solid #fff;
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fff;
  color: #555;
  display: block;
  float: left;
  font-size: 12px;
  height: 28px;
  line-height: 28px;
  margin: 0px;
  padding: 0px;
  text-align: center;
  text-decoration: none;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  width: 34px;
}

/* different states */
.calweek a:hover,
.calfocus {
  /*color: #ab050d !important;*/
}

/* sunday */
a.calsun {
  /*color: ab050d;*/
}

/* offmonth day */
a.caloff {
  background-color: #f5f6f7 !important;
  border: 1px solid #f5f6f7 !important;
  color: #c1c0b8 !important;
  cursor: default !important;
}

a.caloff:hover {
  background-color: #f5f6f7 !important;
  color: #c1c0b8 !important;
  cursor: default !important;
}

/* unselecteble day */
a.caldisabled {
  background-color: #f5f6f7 !important;
  border: 1px solid #f5f6f7 !important;
  color: #c1c0b8 !important;
  cursor: default !important;
}

a.caldisabled:hover {
  background-color: #f5f6f7 !important;
  color: #c1c0b8 !important;
  cursor: default !important;
}

/* current date */
#calcurrent {
  background-color: #ec6615 !important;
  border: 1px solid #ec6615 !important;
  border-left: none !important;
  border-top: none !important;
  color: #ede6df !important;
  text-shadow: 0 1px 0 #767575 !important;
}

#calcurrent:hover {
  color: #fff !important;
}

/* today */
#caltoday {
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%) !important;
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255, 255, 255, 0.05)), color-stop(100%, rgba(0, 0, 0, 0.05))) !important;
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%) !important;
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, rgba(255, 255, 255, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%) !important;
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, rgba(255, 255, 255, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%) !important;
  /* IE10+ */
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%) !important;
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#26ffffff', endColorstr='#26000000',GradientType=0 ) !important;
  /* IE6-9 */
  background-color: #555 !important;
  border: 1px solid #555 !important;
  border-left: 1px solid #2d373b !important;
  border-top: 1px solid #2d373b !important;
  color: #c1c0b8 !important;
  text-shadow: 0 1px 0 #2d373b;
}

#caltoday:hover {
  color: #fff !important;
}

#caldays {
  height: 14px;
}

#caldays span {
  display: block;
  float: left;
  width: 33px;
  text-align: center;
}

#caldays {
  height: 28px;
  display: flex;
  width: 100%;
  padding: 5px 0px 5px 0px;
}

#caldays span {
  float: inherit;
  width: 34px;
  padding: 3px 0px;
  text-align: center;
  display: inline-block;
}

.reservationPanelWrap--slim {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: none;
}

@media (min-width: 1050px) {
  .reservationPanelWrap--slim {
    display: block;
  }
}

.reservationPanelWrap--slim .reservationPanel {
  padding: 0;
}

.reservationPanelWrap.fixed {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: none;
}

@media (min-width: 1050px) {
  .reservationPanelWrap.fixed {
    display: block;
  }
}

.reservationPanelWrap.fixed h5 {
  display: none;
}

.reservationPanelWrap.fixed .reservationPanel {
  padding: 0;
}

.reservationPanelWrap.fixed .reservationPanel .form-group {
  margin-top: 0 !important;
}

.reservationPanelWrap.fixed .reservationPanel .-form button {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}

.mainPhotoDate {
  position: relative;
}

.mainPhotoDate .date {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #fff;
  padding: 6px 15px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.sidebarOtherPosts {
  border: 2px solid #e6e6e6;
  display: block;
  padding: 55px 15px 40px;
}

.sidebarOtherPosts .item {
  text-align: center;
  width: 200px !important;
  border-bottom: 1px solid #ec6615;
  margin: 0px auto 20px auto;
  padding: 10px 5px 30px 5px;
}

.sidebarOtherPosts .item a {
  color: #333333;
}

.sidebarOtherPosts .item .date {
  font-size: 10px;
  font-family: "Montserrat", sans-serif;
  padding-bottom: 3px;
  text-transform: uppercase;
}

.sidebarOtherPosts .item .title {
  font-family: 'GoudyOlStPL';
}

.slick-vertical .slick-slide {
  display: flex;
  justify-content: center;
}

.slick-vertical .slick-next,
.slick-vertical .slick-prev {
  right: unset;
  left: 50%;
  transform: translateX(-50%);
}

.slick-vertical .slick-next:before,
.slick-vertical .slick-prev:before {
  content: "";
}

.slick-vertical .slick-next {
  top: -35px;
  background-image: url("../images/vertical_arrow_top.png");
  width: 44px;
  height: 24px;
}

.slick-vertical .slick-prev {
  top: unset;
  bottom: -25px;
  background-image: url("../images/vertical_arrow_bottom.png");
  width: 44px;
  height: 24px;
}

.formContact {
  background-color: #e6e6e6;
  padding: 30px;
}

.formContact input[type="text"],
.formContact input[type="email"],
.formContact input[type="tel"],
.formContact textarea {
  font-size: 13px;
  border-radius: 0;
  transition: .3s;
  padding-top: 11px;
  padding-bottom: 11px;
  height: auto;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
}

.formContact input[type="text"]:focus,
.formContact input[type="email"]:focus,
.formContact input[type="tel"]:focus,
.formContact textarea:focus {
  padding-left: 15px;
  border-color: #ec6615;
  box-shadow: 0 0 0 0.2rem rgba(236, 102, 21, 0.25);
}

.formContact .primaryBtn {
  min-width: 130px;
}

.formContact .form-group {
  margin-bottom: .7rem;
}

.formContact .agree {
  font-size: 11px;
}

.formContact span.wpcf7-not-valid-tip {
  color: #f00;
  font-size: 12px;
}

.formContact span.wpcf7-list-item {
  margin-left: 0;
  margin-right: 5px;
}

.formContact label {
  cursor: pointer;
}

div.wpcf7-validation-errors,
div.wpcf7-acceptance-missing {
  font-size: 13px;
}

.upArrow {
  display: none;
  position: fixed;
  right: 25px;
  bottom: 15px;
  cursor: pointer;
  z-index: 50;
}

@media (min-width: 1050px) {
  .upArrow {
    bottom: 100px;
  }
}

.hotelsList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #474747;
}

@media (min-width: 1050px) {
  .hotelsList {
    position: relative;
    z-index: 10;
  }
}

.hotelsList .item {
  width: 100%;
  margin: 1%;
}

@media (min-width: 576px) {
  .hotelsList .item {
    width: 48%;
    margin: 1%;
  }
}

@media (min-width: 768px) {
  .hotelsList .item {
    width: 32%;
    margin: .5%;
  }
}

@media (min-width: 992px) {
  .hotelsList .item {
    width: 24%;
    margin: .5%;
  }
}

@media (min-width: 1200px) {
  .hotelsList .item {
    width: 19%;
    margin: .5%;
  }
}

.hotelsList .item img {
  transition: .3s;
}

.hotelsList .item:hover {
  z-index: 222;
}

.hotelsList .item:hover img:hover {
  cursor: pointer;
  transform: scale(1.17);
  position: absolute;
  z-index: 222222222;
  box-shadow: 0 20px 50px rgba(236, 102, 21, 0.7);
}

.hotelsList .item:nth-child(odd):hover img:hover {
  cursor: pointer;
  transform: scale(1.17);
  position: absolute;
  z-index: 222222222;
}

.header {
  position: fixed;
  width: 100%;
}

.header .headerTop {
  padding-top: 10px;
  padding-bottom: 5px;
  transition: .3s;
}

.header .headerBottom .navbar {
  transition: .3s;
}

.header .mainLogoColor {
  display: none;
}

.header.headerSimple {
  background-color: #e9e9e9;
}

.header.headerSimple .headerTop {
  padding-top: 0;
  padding-bottom: 0;
}

.header.headerSimple .headerBottom .navbar {
  margin-top: 0;
}

@media (min-width: 1350px) {
  .header.headerSimple .headerBottom .navbar .navbar-nav .nav-item .nav-link {
    padding: 4px 5px;
    font-size: 13px;
  }
}

@media (min-width: 1500px) {
  .header.headerSimple .headerBottom .navbar .navbar-nav .nav-item .nav-link {
    padding: 4px 20px;
    font-size: 13px;
  }
}

.header.headerSimple .navbar-brand {
  padding-top: 0;
}

.header.headerSimple .navbar-brand__logo {
  transform: scale(0.7);
}

.header.headerSimple .mainLogoColor {
  display: block;
}

.header.headerSimple .mainLogoWhite {
  display: none;
}

.dom {
  padding-top: 0;
  border-bottom: 40px solid #e5d9c6;
  padding-bottom: 0;
  background-color: #fff;
}

@media (min-width: 768px) {
  .dom {
    border-bottom: 0;
  }
}

@media (min-width: 1440px) {
  .dom .titleSection.first {
    font-size: 72px;
  }
}

.dom .leftCol {
  background-color: #e5d9c6;
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: center;
}

@media (min-width: 768px) {
  .dom .leftCol {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}

.dom .leftCol img {
  position: relative;
  z-index: 10;
}

@media (min-width: 768px) {
  .dom .leftCol img {
    margin-left: 3%;
  }
}

@media (min-width: 1100px) {
  .dom .leftCol img {
    margin-left: 12%;
  }
}

@media (min-width: 1200px) {
  .dom .leftCol img {
    margin-left: 15%;
  }
}

@media (min-width: 1300px) {
  .dom .leftCol img {
    margin-left: 20%;
  }
}

@media (min-width: 1400px) {
  .dom .leftCol img {
    margin-left: 25%;
  }
}

@media (min-width: 1500px) {
  .dom .leftCol img {
    margin-left: 30%;
  }
}

@media (min-width: 1650px) {
  .dom .leftCol img {
    margin-left: 35%;
  }
}

@media (min-width: 1800px) {
  .dom .leftCol img {
    margin-left: 40%;
  }
}

.dom .rightCol {
  background-image: url("../images/dom_bg_element.jpg");
  background-position: 90% 90%;
  background-repeat: no-repeat;
  padding-top: 25px;
  padding-bottom: 25px;
  border-left: 20px solid #e5d9c6;
  border-right: 20px solid #e5d9c6;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 768px) {
  .dom .rightCol {
    border-left: 10px solid #e5d9c6;
    border-right: 0;
  }
}

@media (min-width: 1100px) {
  .dom .rightCol .content {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.dom .rightCol .content .textWrap {
  max-width: 500px;
  margin: 0 auto;
  padding-top: 25px;
  padding-bottom: 25px;
}

.dom .rightCol .content .textWrap p {
  text-align: justify;
}

.dom .rightCol .content .btnBox {
  padding-top: 25px;
  padding-bottom: 25px;
}

.rooms {
  background-color: #efece8;
  position: relative;
  padding-top: 40px;
  background-image: url("../images/room_home_bg_element.jpg");
  background-repeat: no-repeat;
  background-position: 100% 99.5%;
}

@media (min-width: 768px) {
  .rooms {
    padding-top: 50px;
  }
}

.rooms .title {
  margin: 0 auto 40px auto;
  width: 270px;
  height: 270px;
  text-align: center;
  background-color: #fff;
  color: #000;
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 768px) {
  .rooms .title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 12;
    margin: 0;
  }
}

@media (min-width: 992px) {
  .rooms .title {
    width: 300px;
    height: 300px;
  }
}

.rooms .title:hover, .rooms .title:active, .rooms .title:focus {
  text-decoration: none;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.rooms .title h3 {
  font-size: 50px;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

@media (min-width: 992px) {
  .rooms .title h3 {
    font-size: 50px;
  }
}

.rooms .title h3::before {
  position: absolute;
  top: 0;
  left: -75%;
  z-index: 2;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
  background: -webkit-linear-gradient(left, rgba(229, 217, 198, 0) 0%, rgba(229, 217, 198, 0.3) 100%);
  background: linear-gradient(to right, rgba(229, 217, 198, 0) 0%, rgba(229, 217, 198, 0.3) 100%);
  -webkit-transform: skewX(-25deg);
  transform: skewX(-25deg);
}

.rooms .title h3:hover::before, .rooms .title h3:active::before, .rooms .title h3:focus::before {
  animation: shine 0.85s;
}

@keyframes shine {
  100% {
    left: 125%;
  }
}

.rooms .item {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.rooms .item button {
  z-index: 10;
  position: absolute;
  transition: 0.45s;
}

.rooms .item:hover img {
  transform: scale(1.07);
}

.rooms .item--1 {
  height: 100%;
  min-height: 350px;
}

@media (min-width: 768px) {
  .rooms .item--1 {
    min-height: 600px;
    height: 90vh;
  }
}

.rooms .item--1:hover button, .rooms .item--1:active button, .rooms .item--1:focus button {
  bottom: 50px;
}

.rooms .item--1 button {
  bottom: 50px;
  left: 20px;
}

@media (min-width: 768px) {
  .rooms .item--1 button {
    bottom: -100px;
  }
}

.rooms .item--2 {
  height: 100%;
  min-height: 350px;
}

@media (min-width: 768px) {
  .rooms .item--2 {
    min-height: inherit;
  }
}

.rooms .item--2:hover button, .rooms .item--2:active button, .rooms .item--2:focus button {
  top: 30px;
}

.rooms .item--2 button {
  top: 30px;
  left: 20px;
}

@media (min-width: 768px) {
  .rooms .item--2 button {
    top: -100px;
  }
}

.rooms .item--3 {
  height: 100%;
  min-height: 350px;
}

@media (min-width: 768px) {
  .rooms .item--3 {
    min-height: inherit;
  }
}

.rooms .item--3:hover button, .rooms .item--3:active button, .rooms .item--3:focus button {
  bottom: 50px;
}

.rooms .item--3 button {
  bottom: 50px;
  left: 20px;
}

@media (min-width: 768px) {
  .rooms .item--3 button {
    bottom: -100px;
  }
}

.rooms .item img {
  min-height: 50%;
  transition: 0.45s;
}

.youAboutUs {
  padding-top: 0;
  padding-bottom: 0;
}

@media (min-width: 768px) {
  .youAboutUs {
    padding-bottom: 50px;
  }
}

@media (min-width: 1400px) {
  .youAboutUs {
    padding-bottom: 100px;
  }
}

.specialOffersHome {
  max-width: 1400px;
  padding: 30px 25px 50px 25px;
  margin: 0 auto;
  background-image: url("../images/specialOffersHome_bg.jpg");
  z-index: 5;
  position: relative;
}

@media (min-width: 768px) {
  .specialOffersHome {
    padding: 25px;
  }
}

@media (min-width: 1440px) {
  .specialOffersHome {
    padding: 40px 25px;
  }
}

.specialOffersHome .titleSection {
  color: black;
}

@media (min-width: 992px) {
  .specialOffersHome .titleSection {
    font-size: 60px;
  }
}

.specialOffersHome .firstCol {
  padding-top: 30px;
  padding-bottom: 30px;
}

@media (min-width: 768px) {
  .specialOffersHome .firstCol {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

@media (min-width: 768px) {
  .specialOffersHome .firstCol {
    border-right: 1px solid black;
  }
}

.specialOffersHome .titleOffer {
  font-size: 24px;
  font-weight: 300;
  font-family: "Lato", sans-serif;
}

@media (min-width: 460px) {
  .specialOffersHome .titleOffer {
    font-size: 26px;
  }
}

@media (min-width: 576px) {
  .specialOffersHome .titleOffer {
    font-size: 26px;
  }
}

@media (min-width: 768px) {
  .specialOffersHome .titleOffer {
    font-size: 28px;
  }
}

@media (min-width: 992px) {
  .specialOffersHome .titleOffer {
    font-size: 28px;
  }
}

@media (min-width: 1440px) {
  .specialOffersHome .titleOffer {
    font-size: 32px;
  }
}

.imageSection {
  width: 100%;
  padding: 0;
  height: 400px;
  background-image: url("../images/ptak.jpg");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  overflow: hidden;
}

@media (min-width: 768px) {
  .imageSection {
    background-attachment: fixed;
  }
}

.imageSection--goTop {
  margin-top: -60px;
}

@media (min-width: 992px) {
  .imageSection {
    height: 450px;
  }
}

@media (min-width: 1400px) {
  .imageSection {
    height: 550px;
  }
}

@media (min-width: 1600px) {
  .imageSection {
    height: 600px;
  }
}

.slideNav {
  background-color: #474747;
  position: fixed;
  height: 100vh;
  top: 0;
  right: -500px;
  width: 100%;
  max-width: 500px;
  z-index: 100;
  overflow-y: auto;
  transition: 0.4s;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (min-width: 450px) {
  .slideNav {
    max-width: 325px;
  }
}

@media (min-width: 1200px) {
  .slideNav {
    max-width: 400px;
  }
}

@media (min-width: 1600px) {
  .slideNav {
    max-width: 500px;
  }
}

@media (min-width: 1900px) {
  .slideNav {
    max-width: 550px;
    right: -551px;
  }
}

@media (min-width: 2000px) {
  .slideNav {
    right: -601px;
    max-width: 600px;
  }
}

.slideNav.active {
  right: 0;
}

.slideNav .navbar {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) {
  .slideNav .navbar .navbar-nav {
    overflow-y: scroll;
    height: 65vh;
  }
}

.slideNav .navbar .navbar-nav .dropdown-menu {
  width: auto;
  position: static;
  border: none;
}

.slideNav .navbar .navbar-nav .dropdown-menu .nav-link {
  display: block !important;
}

.slideNav .navbar .navbar-nav .dropdown-menu .hvr-underline-from-center:before {
  height: 1px;
}

.slideNav .navbar .navbar-nav .nav-item.dropdown::after {
  top: 18px;
}

@media (min-width: 992px) {
  .slideNav .navbar .navbar-nav .nav-item.dropdown::after {
    top: 22px;
  }
}

@media (min-width: 1200px) {
  .slideNav .navbar .navbar-nav .nav-item.dropdown::after {
    top: 25px;
  }
}

.slideNav .navbar .navbar-nav .nav-item .nav-link {
  font-family: Montserrat,sans-serif;
  font-weight: 300;
  color: #fff;
  padding: 5px;
  text-transform: uppercase;
  display: block;
  font-size: 22px;
}

@media (min-width: 768px) {
  .slideNav .navbar .navbar-nav .nav-item .nav-link {
    font-size: 23px;
  }
}

@media (min-width: 992px) {
  .slideNav .navbar .navbar-nav .nav-item .nav-link {
    font-size: 24px;
    margin: 2px 0;
  }
}

@media (min-width: 1440px) {
  .slideNav .navbar .navbar-nav .nav-item .nav-link {
    font-size: 25px;
  }
}

.socialIcons img {
  width: 23px;
  height: 23px;
  margin: 4px;
}

.socialIcons--big img {
  width: 30px;
  height: 30px;
  margin-left: 4px;
}

.lang {
  background-color: transparent;
  margin-right: 15px;
}

.lang.show button {
  background-color: rgba(71, 71, 71, 0.93) !important;
  border-color: #fff;
}

.lang button {
  background-color: transparent;
  font-size: 14px;
  border-color: #fff;
}

.lang button:hover, .lang button:active, .lang button:focus {
  background-color: rgba(71, 71, 71, 0.93);
  border-color: #fff;
}

.lang .dropdown-menu {
  min-width: 46px;
}

.lang .dropdown-menu a {
  display: block;
  color: #0f3959;
  padding: 4px 6px;
  font-size: 14px;
}

.lang .dropdown-menu a:hover, .lang .dropdown-menu a:active, .lang .dropdown-menu a:focus {
  text-decoration: none;
  background-color: lightgray;
}

.headerSimple .lang {
  color: #333333;
}

.headerSimple .lang button {
  border-color: #333333;
  color: #333333;
}

.headerSimple .lang button:hover, .headerSimple .lang button:active, .headerSimple .lang button:focus {
  background-color: transparent;
  border-color: #333333;
}

.fixedHeader .navbar-brand {
  position: fixed;
  top: 0;
  left: -160px;
  background-color: rgba(71, 71, 71, 0.1);
  z-index: 56;
  padding: 10px;
  transition: 0.4s;
}

@media (min-width: 576px) {
  .fixedHeader .navbar-brand {
    padding: 6px 15px 15px 15px;
  }
}

.fixedHeader .navbar-brand__logo {
  width: 120px;
  height: auto;
}

@media (min-width: 576px) {
  .fixedHeader .navbar-brand__logo {
    width: 125px;
  }
}

@media (min-width: 768px) {
  .fixedHeader .navbar-brand__logo {
    width: 130px;
  }
}

@media (min-width: 992px) {
  .fixedHeader .navbar-brand__logo {
    width: 130px;
  }
}

.fixedHeader .rightPanel {
  position: fixed;
  top: 12px;
  right: -240px;
  background-color: rgba(71, 71, 71, 0.93);
  z-index: 2;
  padding: 15px 20px;
  transition: 0.4s;
}

@media (min-width: 576px) {
  .fixedHeader .rightPanel {
    padding: 15px 20px;
    top: 15px;
  }
}

@media (min-width: 992px) {
  .fixedHeader .rightPanel {
    top: 35px;
  }
}

@media (min-width: 1200px) {
  .fixedHeader .rightPanel {
    padding: 20px 30px;
  }
}

.fixedHeader.active .navbar-brand {
  left: 0;
}

.fixedHeader.active .rightPanel {
  right: 0;
}

.burgerIcon {
  max-width: 30px;
  width: 30px;
  height: 23px;
}

.burgerIcon--white {
  display: block;
}

.burgerIcon--gray {
  display: none;
}

@media (min-width: 768px) {
  .burgerIcon {
    max-width: 40px;
    width: 40px;
    height: 30px;
  }
}

.burgerIcon:hover {
  cursor: pointer;
}

.headerSimple .burgerIcon--white {
  display: none;
}

.headerSimple .burgerIcon--gray {
  display: block;
}

.navbar-nav .dropdown-menu {
  background-color: rgba(71, 71, 71, 0.7);
  position: absolute;
  max-width: unset;
}

.navbar-nav .dropdown-menu .nav-link {
  font-size: 14px !important;
}

.navbar-nav .hotelsDropdown .dropdown-menu {
  background-color: rgba(71, 71, 71, 0.7);
  position: absolute;
  max-width: unset;
  min-width: 97vw;
}

@media (min-width: 1070px) {
  .navbar-nav .hotelsDropdown .dropdown-menu {
    min-width: 1000px;
  }
}

.navbar-nav .hotelsDropdown .dropdown-menu .nav-link {
  display: inline-block !important;
  font-size: 13px !important;
  text-align: left !important;
  text-transform: unset !important;
  padding: 9px 15px !important;
  max-width: unset !important;
  width: 33% !important;
}

@media (min-width: 1070px) {
  .navbar-nav .hotelsDropdown .dropdown-menu .nav-link {
    width: 330px !important;
  }
}

.nav-item.dropdown::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent;
  position: absolute;
  right: 6px;
  top: 48%;
  color: #fff;
}

.mainSlider {
  padding-top: 0 !important;
  padding-bottom: 0;
  min-height: 100vh;
}

@media (min-width: 1050px) {
  .mainSlider {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
  }
}

.mainSlider .item {
  min-height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  display: none;
}

@media (min-width: 576px) {
  .mainSlider .item {
    height: 100vh;
  }
}

@media (min-width: 768px) {
  .mainSlider .item {
    height: 100vh;
  }
}

@media (min-width: 992px) {
  .mainSlider .item {
    height: 100vh;
  }
}

.mainSlider .item .overlay {
  background: rgba(0, 0, 0, 0.1);
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.mainSlider .item .textBox {
  position: absolute;
  top: 40%;
  width: 100%;
  padding-left: 45px;
  padding-right: 45px;
}

.mainSlider .item .textBox .mainText {
  color: #fff;
  font-size: 38px;
  text-shadow: 5px 5px 5px rgba(0, 0, 0, 0.4);
  text-transform: inherit;
}

@media (min-width: 576px) {
  .mainSlider .item .textBox .mainText {
    font-size: 40px;
  }
}

@media (min-width: 768px) {
  .mainSlider .item .textBox .mainText {
    font-size: 45px;
  }
}

@media (min-width: 992px) {
  .mainSlider .item .textBox .mainText {
    font-size: 50px;
  }
}

@media (min-width: 1200px) {
  .mainSlider .item .textBox .mainText {
    font-size: 50px;
  }
}

@media (min-width: 1400px) {
  .mainSlider .item .textBox .mainText {
    font-size: 60px;
  }
}

@media (min-width: 1600px) {
  .mainSlider .item .textBox .mainText {
    font-size: 72px;
  }
}

.mainSlider .item .textBox .secondText {
  color: #fff;
  text-shadow: 5px 5px 5px rgba(0, 0, 0, 0.4);
  padding-top: 5px;
}

.mainSlider .item .textBox .secondText,
.mainSlider .item .textBox .secondText > * {
  font-size: 23px;
}

@media (min-width: 576px) {
  .mainSlider .item .textBox .secondText,
  .mainSlider .item .textBox .secondText > * {
    font-size: 26px;
  }
}

@media (min-width: 768px) {
  .mainSlider .item .textBox .secondText,
  .mainSlider .item .textBox .secondText > * {
    font-size: 28px;
  }
}

@media (min-width: 992px) {
  .mainSlider .item .textBox .secondText,
  .mainSlider .item .textBox .secondText > * {
    font-size: 30px;
  }
}

@media (min-width: 1200px) {
  .mainSlider .item .textBox .secondText,
  .mainSlider .item .textBox .secondText > * {
    font-size: 30px;
  }
}

@media (min-width: 1400px) {
  .mainSlider .item .textBox .secondText,
  .mainSlider .item .textBox .secondText > * {
    font-size: 36px;
  }
}

.mainSlider .item .textBox .btnBox {
  padding-top: 5px;
}

@media (min-width: 768px) {
  .mainSlider .item .textBox .btnBox {
    padding-top: 20px;
  }
}

.mainSlider .item.onMobile {
  display: block;
}

@media (min-width: 768px) {
  .mainSlider .item.onMobile {
    display: none;
  }
}

.mainSlider .item.onDesktop {
  display: none;
}

@media (min-width: 768px) {
  .mainSlider .item.onDesktop {
    display: block;
  }
}

.mainSlider .item.onMobile.onDesktop {
  display: block;
}

.mainSlider .owl-theme .owl-nav {
  width: 100%;
  max-width: 1630px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: space-between;
  height: 0;
  margin-top: -40px;
}

.mainSlider .owl-theme .owl-dots {
  position: absolute;
  bottom: 10px;
  text-align: center;
  margin: 0 auto;
  left: 50%;
  transform: translate(-50%);
}

.mainSlider .owl-theme .owl-dots .owl-dot span {
  margin: 5px 4px;
  background-color: #fff;
}

.mainSlider .owl-theme .owl-dots .owl-dot.active span,
.mainSlider .owl-theme .owl-dots .owl-dot:hover span {
  background-color: #d6d6d6;
}

.mainSlider .owl-carousel .owl-nav button.owl-prev,
.mainSlider .owl-carousel .owl-nav button.owl-next {
  width: 15px;
  height: 17px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin: 20px 5px;
}

@media (min-width: 576px) {
  .mainSlider .owl-carousel .owl-nav button.owl-prev,
  .mainSlider .owl-carousel .owl-nav button.owl-next {
    margin: 20px;
  }
}

@media (min-width: 992px) {
  .mainSlider .owl-carousel .owl-nav button.owl-prev,
  .mainSlider .owl-carousel .owl-nav button.owl-next {
    width: 20px;
    height: 32px;
  }
}

.mainSlider .owl-carousel .owl-nav button.owl-prev:hover,
.mainSlider .owl-carousel .owl-nav button.owl-next:hover {
  background-color: transparent;
}

.mainSlider .owl-carousel .owl-nav button.owl-prev {
  background-image: url("../images/owl-left.png");
}

.mainSlider .owl-carousel .owl-nav button.owl-next {
  background-image: url("../images/owl-right.png");
}

section {
  padding-top: 30px;
  padding-bottom: 30px;
  overflow: hidden;
}

.specialOffers {
  color: #0f3959;
}

.specialOffers a {
  color: #0f3959;
}

.specialOffers a .hvr-ripple-out:before {
  border: 6px solid #ec6615;
  animation-duration: .7s;
}

.specialOffers a:hover, .specialOffers a:active, .specialOffers a:focus {
  text-decoration: none;
  color: #0f3959 !important;
}

.specialOffers a:hover .photo .psuedo-background-img, .specialOffers a:active .photo .psuedo-background-img, .specialOffers a:focus .photo .psuedo-background-img {
  transform: scale(1.03);
}

.specialOffers a:hover .hvr-ripple-out:before, .specialOffers a:hover .hvr-ripple-out:before, .specialOffers a:hover .hvr-ripple-out:before, .specialOffers a:active .hvr-ripple-out:before, .specialOffers a:active .hvr-ripple-out:before, .specialOffers a:active .hvr-ripple-out:before, .specialOffers a:focus .hvr-ripple-out:before, .specialOffers a:focus .hvr-ripple-out:before, .specialOffers a:focus .hvr-ripple-out:before {
  -webkit-animation-name: hvr-ripple-out;
  animation-name: hvr-ripple-out;
}

.specialOffers .item {
  border-bottom: 2px solid #ec6615;
  margin-top: 25px !important;
}

.specialOffers .item .titleOffer {
  padding-bottom: 10px;
  font-size: 18px;
  font-family: "Amsi Pro";
}

@media (min-width: 768px) {
  .specialOffers .item .titleOffer {
    font-size: 22px;
  }
}

@media (min-width: 992px) {
  .specialOffers .item .titleOffer {
    font-size: 24px;
    padding-bottom: 10px;
  }
}

.specialOffers .item .titleOffer strong {
  font-family: "Amsi Pro Black";
}

.specialOffers .item .infoBox .photo {
  height: 190px;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}

.specialOffers .item .infoBox .photo .psuedo-background-img {
  transition: .25s;
}

@media (min-width: 400px) {
  .specialOffers .item .infoBox .photo {
    height: 230px;
  }
}

@media (min-width: 576px) {
  .specialOffers .item .infoBox .photo {
    height: 280px;
  }
}

@media (min-width: 768px) {
  .specialOffers .item .infoBox .photo {
    height: 400px;
  }
}

@media (min-width: 992px) {
  .specialOffers .item .infoBox .photo {
    height: 260px;
  }
}

@media (min-width: 1200px) {
  .specialOffers .item .infoBox .photo {
    height: 300px;
  }
}

.specialOffers .item .infoBox .panel {
  background-color: #e7e6e5;
}

.specialOffers .item .infoBox .panel .elementWrap {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 5px;
  padding-right: 5px;
  min-height: 119px;
}

@media (min-width: 350px) {
  .specialOffers .item .infoBox .panel .elementWrap {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 400px) {
  .specialOffers .item .infoBox .panel .elementWrap {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

@media (min-width: 576px) {
  .specialOffers .item .infoBox .panel .elementWrap {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.specialOffers .item .infoBox .panel .elementWrap p {
  font-weight: 500;
  font-size: 14px;
  line-height: 23px;
}

@media (min-width: 350px) {
  .specialOffers .item .infoBox .panel .elementWrap p {
    font-size: 14px;
  }
}

@media (min-width: 576px) {
  .specialOffers .item .infoBox .panel .elementWrap p {
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  .specialOffers .item .infoBox .panel .elementWrap p {
    max-width: 80%;
  }
}

.specialOffers .item .infoBox .panel .elementWrap .element {
  font-weight: 500;
  font-size: 14px;
}

@media (min-width: 350px) {
  .specialOffers .item .infoBox .panel .elementWrap .element {
    font-size: 15px;
  }
}

@media (min-width: 576px) {
  .specialOffers .item .infoBox .panel .elementWrap .element {
    font-size: 18px;
  }
}

.specialOffers .item .infoBox .panel .elementWrap.centerCol {
  border-right: 1px solid #0f3959;
  padding-left: 40px;
  padding-top: 20px;
}

.specialOffers .item .infoBox .btnBox .arrow {
  width: 57px;
  height: 57px;
  background-color: #ec6615;
  background-image: url("../images/offers_arrow.png");
  background: url("../images/offers_arrow.png") no-repeat top 50% left 50% #ec6615;
  margin-left: auto;
  display: block;
}

@media (min-width: 768px) {
  .specialOffers .item .infoBox .btnBox {
    margin-top: -30px;
  }
}

.specialOffers .item .infoBox .textWrap {
  font-family: 'Open Sans', sans-serif;
  padding-top: 10px;
  line-height: 25px;
}

.specialOffers .item .infoBox .textWrap .downIcon img {
  max-width: 50px;
}

@media (min-width: 768px) {
  .specialOffers .item .infoBox .textWrap .downIcon img {
    max-width: 60px;
  }
}

@media (min-width: 992px) {
  .specialOffers .item .infoBox .textWrap .downIcon img {
    max-width: 77px;
  }
}

.specialOffers .item .infoBox .textWrap .downIcon img:hover {
  cursor: pointer;
}

.specialOffers .item .infoBox .textWrap .mainInfo,
.specialOffers .item .infoBox .textWrap .moreInfo {
  font-size: 15px;
}

@media (min-width: 576px) {
  .specialOffers .item .infoBox .textWrap .mainInfo,
  .specialOffers .item .infoBox .textWrap .moreInfo {
    font-size: 15px;
  }
}

@media (min-width: 768px) {
  .specialOffers .item .infoBox .textWrap .mainInfo,
  .specialOffers .item .infoBox .textWrap .moreInfo {
    font-size: 16px;
  }
}

@media (min-width: 992px) {
  .specialOffers .item .infoBox .textWrap .mainInfo,
  .specialOffers .item .infoBox .textWrap .moreInfo {
    font-size: 17px;
    min-height: 175px;
  }
}

.specialOffers .item .infoBox .textWrap .mainInfo a,
.specialOffers .item .infoBox .textWrap .moreInfo a {
  color: #0f3959;
}

.specialOffers .item .infoBox .textWrap .mainInfo table,
.specialOffers .item .infoBox .textWrap .moreInfo table {
  font-size: 12px;
  color: #0f3959;
}

@media (min-width: 576px) {
  .specialOffers .item .infoBox .textWrap .mainInfo table,
  .specialOffers .item .infoBox .textWrap .moreInfo table {
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  .specialOffers .item .infoBox .textWrap .mainInfo table,
  .specialOffers .item .infoBox .textWrap .moreInfo table {
    font-size: 15px;
  }
}

@media (min-width: 992px) {
  .specialOffers .item .infoBox .textWrap .mainInfo table,
  .specialOffers .item .infoBox .textWrap .moreInfo table {
    font-size: 15px;
  }
}

.specialOffers .item .infoBox .textWrap .mainInfo table td, .specialOffers .item .infoBox .textWrap .mainInfo table th,
.specialOffers .item .infoBox .textWrap .moreInfo table td,
.specialOffers .item .infoBox .textWrap .moreInfo table th {
  padding: .2rem;
}

@media (min-width: 576px) {
  .specialOffers .item .infoBox .textWrap .mainInfo table,
  .specialOffers .item .infoBox .textWrap .moreInfo table {
    padding: .75rem;
  }
}

.specialOffers .item .infoBox .textWrap .mainInfo {
  text-align: justify;
}

.specialOffers .item .infoBox .textWrap .moreInfo {
  padding-top: 15px;
  display: none;
}

.specialOffers .item .infoBox .textWrap.active .downIcon img {
  transform: rotate(180deg);
}

.specialOffers .item .infoBox .panelBottom {
  padding-top: 10px;
}

.specialOffers .item .infoBox .panelBottom .btnBox {
  width: 50%;
}

.specialOffers .item .infoBox .panelBottom .offerBox a {
  font-size: 13px;
  color: #0f3959;
  text-transform: uppercase;
}

@media (min-width: 576px) {
  .specialOffers .item .infoBox .panelBottom .offerBox a {
    font-size: 14px;
  }
}

.specialOffers .item .infoBox .panelBottom .offerBox a svg {
  width: 35px;
  height: auto;
  margin-left: 7px;
  fill: #0f3959;
}

@media (min-width: 576px) {
  .specialOffers .item .infoBox .panelBottom .offerBox a svg {
    margin-left: 10px;
    width: 40px;
  }
}

.location {
  overflow: hidden;
  padding-top: 50px;
  padding-bottom: 0;
}

@media (min-width: 768px) {
  .location {
    padding-top: 60px;
    padding-bottom: 0;
  }
}

@media (min-width: 1400px) {
  .location {
    padding-top: 100px;
    padding-bottom: 0;
  }
}

.location .titleSection {
  padding-bottom: 20px;
}

@media (min-width: 576px) {
  .location .titleSection {
    padding-bottom: 25px;
  }
}

#map {
  height: 500px;
  width: 100%;
}

footer {
  position: relative;
  z-index: 10;
  background-color: #fff;
  /*footer bottom*/
  /* ./footer bottom*/
}

footer .footerTop {
  background-color: #ebebeb;
  padding-top: 20px;
  padding-bottom: 20px;
}

footer .footerTop .logo {
  margin-left: 2px;
}

footer .footerTop .logo img {
  width: 145px;
}

footer .footerTop .nav .nav-link {
  font-size: 14px;
  color: #333;
  padding: 10px;
  text-transform: uppercase;
  font-weight: 300;
}

footer .footerTop .nav .nav-link::before {
  display: none;
}

footer .footerTop .nav .nav-link:hover, footer .footerTop .nav .nav-link:active, footer .footerTop .nav .nav-link:focus {
  text-decoration: underline;
}

footer .footerTop .nav .nav-link:hover::before, footer .footerTop .nav .nav-link:active::before, footer .footerTop .nav .nav-link:focus::before {
  display: none;
}

footer .footerTop .socialIcons img {
  width: 22px;
  height: 22px;
}

footer .footerTop .centerCol {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-left: 1px solid #c2c2c2;
  border-right: 1px solid #c2c2c2;
}

footer .copyBox {
  padding-bottom: 100px;
  background-color: #fff;
  text-align: center;
  color: #000;
  margin-top: 10px;
}

footer .copyBox .copyBoxInner {
  padding-top: 10px;
  padding-bottom: 15px;
}

footer .copyBox .copyBoxInner p {
  font-size: 12px;
}

@media (min-width: 576px) {
  footer .copyBox .copyBoxInner p {
    font-size: 14px;
  }
}

.socialFixed {
  width: 30px;
  height: 250px;
  position: fixed;
  top: 35%;
  right: 0;
  display: none;
  z-index: 30;
}

@media (min-width: 576px) {
  .socialFixed {
    display: block;
  }
}

.socialFixed .item {
  margin-top: 5px;
  padding-top: 0;
}

.textSection {
  background-image: url("../images/textSection_bg_element1.jpg");
  background-repeat: no-repeat;
  background-position: bottom right;
  padding-bottom: 100px;
  background-size: 300px;
}

@media (min-width: 768px) {
  .textSection {
    padding-bottom: 155px;
    background-size: unset;
  }
}

.roomEquipment .rightCol {
  background-color: #e5d9c6;
}

.twoRowsSlider .item {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 400px;
  height: 300px;
  position: relative;
  overflow: hidden;
  border-left: 8px solid #fff;
  border-right: 8px solid #fff;
  border-top: 6px solid #fff;
  border-left: 6px solid #fff;
}

.twoRowsSlider .item .psuedo-background-img {
  width: 100%;
  height: 100%;
}

.twoRowsSlider .slick-prev {
  background-image: url("../images/slick-prev-arrow.png");
  width: 20px;
  height: 32px;
  left: -50px;
}

.twoRowsSlider .slick-prev:hover, .twoRowsSlider .slick-prev:active, .twoRowsSlider .slick-prev:focus {
  background-image: url("../images/slick-prev-arrow.png");
}

.twoRowsSlider .slick-prev:before {
  content: unset;
}

.twoRowsSlider .slick-next {
  background-image: url("../images/slick-next-arrow.png");
  width: 20px;
  height: 32px;
  right: -40px;
}

.twoRowsSlider .slick-next:hover, .twoRowsSlider .slick-next:active, .twoRowsSlider .slick-next:focus {
  background-image: url("../images/slick-next-arrow.png");
}

.twoRowsSlider .slick-next:before {
  content: unset;
}

.apartsList {
  background-color: #efece8;
}

.apartsList .listItem .title {
  padding-top: 30px;
  padding-bottom: 30px;
}

.apartsList .listItem .photo {
  position: relative;
  width: 270px;
  height: 270px;
}

@media (min-width: 320px) {
  .apartsList .listItem .photo {
    width: 300px;
    height: 300px;
  }
}

.modal .modal-title {
  font-size: 20px;
}

@media (min-width: 992px) {
  .modal .modal-title {
    font-size: 25px;
  }
}

@media (min-width: 1400px) {
  .modal .modal-title {
    font-size: 30px;
  }
}

.modal .modal-body {
  font-size: 13px;
}

@media (min-width: 576px) {
  .modal .modal-body {
    font-size: 15px;
  }
}

.modal .modal-body h5 {
  font-weight: bold;
  font-size: 16px;
}

@media (min-width: 576px) {
  .modal .modal-body h5 {
    font-size: 18px;
  }
}

.modal .btnBox .yellowSmallBtn,
.modal .btnBox .blueSmallBtn,
.modal .modal-footer .yellowSmallBtn,
.modal .modal-footer .blueSmallBtn {
  background-color: #ec6615 !important;
  color: #fff !important;
}

.modal .btnBox .yellowSmallBtn:hover, .modal .btnBox .yellowSmallBtn:active, .modal .btnBox .yellowSmallBtn:focus,
.modal .btnBox .blueSmallBtn:hover,
.modal .btnBox .blueSmallBtn:active,
.modal .btnBox .blueSmallBtn:focus,
.modal .modal-footer .yellowSmallBtn:hover,
.modal .modal-footer .yellowSmallBtn:active,
.modal .modal-footer .yellowSmallBtn:focus,
.modal .modal-footer .blueSmallBtn:hover,
.modal .modal-footer .blueSmallBtn:active,
.modal .modal-footer .blueSmallBtn:focus {
  background-color: #ec6615 !important;
  color: #fff !important;
}

.modal .btnBox .hvr-ripple-out:before,
.modal .modal-footer .hvr-ripple-out:before {
  border-color: #ec6615 !important;
}

.mobileBottomPanel {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 3000;
  background-color: rgba(71, 71, 71, 0.93);
  display: none;
  padding: 0;
}

.mobileBottomPanel .item {
  background-color: rgba(71, 71, 71, 0.93);
  padding: 7px;
  text-align: center;
  width: 100%;
}

.mobileBottomPanel .item:hover {
  cursor: pointer;
  opacity: .7;
}

.mobileBottomPanel .item img {
  max-width: 18px;
}

.mobileBottomPanel .item p {
  color: #fff;
  font-size: 10px;
  margin-top: 3px;
  text-transform: uppercase;
}

.mobileBottomPanel .item.book {
  background-color: #ec6615;
}

.mobileBottomPanel .item.book p {
  color: #0f3959;
}

#cookie-msg {
  padding: 12px !important;
  background-color: #0f3959 !important;
  z-index: 100;
}

#cookie-msg .msg {
  font-size: 12px;
}

@media (min-width: 576px) {
  #cookie-msg .msg {
    font-size: 14px;
  }
}

#cookie-msg a {
  color: #e35205 !important;
}

#cookie-msg .btn-aceptar {
  background-color: #e35205 !important;
  margin-left: 13px;
  font-size: 13px !important;
  color: #0f3959 !important;
  margin: 2px;
  display: inline-block;
}

.headerBottom {
  background-color: rgba(71, 71, 71, 0.93);
  padding-left: 15px;
  padding-right: 15px;
}

.headerBottom .navbar {
  padding: 0;
  margin-top: 15px;
}

.headerBottom .navbar .navbar-nav {
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
  display: none;
}

@media (min-width: 768px) {
  .headerBottom .navbar .navbar-nav {
    display: flex;
  }
}

.headerBottom .navbar .navbar-nav .nav-item .nav-link {
  padding: 6px 10px;
  color: #fff;
  text-transform: uppercase;
  font-size: 13px;
  max-width: 240px;
  text-align: center;
  display: flex;
  align-items: center;
  height: 100%;
  transition: .3s;
  line-height: 19px;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
}

.headerBottom .navbar .navbar-nav .nav-item .nav-link[data-toggle="dropdown"] {
  padding-right: 25px !important;
}

.headerBottom .navbar .navbar-nav .nav-item .nav-link:hover, .headerBottom .navbar .navbar-nav .nav-item .nav-link:active, .headerBottom .navbar .navbar-nav .nav-item .nav-link:focus {
  background-color: rgba(71, 71, 71, 0.8);
}

@media (min-width: 992px) {
  .headerBottom .navbar .navbar-nav .nav-item .nav-link {
    font-size: 14px;
  }
}

@media (min-width: 1200px) {
  .headerBottom .navbar .navbar-nav .nav-item .nav-link {
    font-size: 14px;
  }
}

@media (min-width: 1350px) {
  .headerBottom .navbar .navbar-nav .nav-item .nav-link {
    padding: 6px 15px;
  }
}

@media (min-width: 1400px) {
  .headerBottom .navbar .navbar-nav .nav-item .nav-link {
    font-size: 14px;
  }
}

@media (min-width: 1500px) {
  .headerBottom .navbar .navbar-nav .nav-item .nav-link {
    padding: 5px 20px;
  }
}

.headerBottom .navbar .navbar-nav .nav-item .hvr-underline-from-center:before {
  background: #fff;
  height: 1px;
}

.welcome {
  background-repeat: no-repeat;
  background-position: right 20%;
}

@media (min-width: 992px) {
  .welcome {
    background-image: url("../images/welcome_bg_element.jpg");
  }
}

.welcome .area {
  font-size: 36px;
  font-family: Lato, sans-serif;
  font-weight: bold;
  color: #0f3959;
}

.welcome .info {
  text-transform: uppercase;
}

.offer {
  overflow: hidden;
}

.offer .item .photoWrap {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 220px;
}

@media (min-width: 450px) {
  .offer .item .photoWrap {
    min-height: 250px;
  }
}

@media (min-width: 576px) {
  .offer .item .photoWrap {
    min-height: 350px;
  }
}

@media (min-width: 1200px) {
  .offer .item .photoWrap {
    min-height: 400px;
  }
}

@media (min-width: 1600px) {
  .offer .item .photoWrap {
    min-height: 450px;
  }
}

@media (min-width: 1800px) {
  .offer .item .photoWrap {
    min-height: 500px;
  }
}

.offer .item .textWrap .textBox {
  max-width: 450px;
  margin: 0 auto;
  padding: 40px 15px 50px 15px;
}

.offer .item .textWrap .textBox .titleSection {
  text-shadow: 2px 2px 7px #fff;
  text-align: left;
  padding-top: 0;
  padding-bottom: 20px;
}

@media (min-width: 1200px) {
  .offer .item .textWrap .textBox .titleSection {
    line-height: 60px;
  }
}

.offer .item .textWrap .textBox p {
  text-align: justify;
}

.offer .item .textWrap .textBox .btnBox {
  padding-top: 30px;
}

@media (min-width: 992px) {
  .offer .item .textWrap .textBox .btnBox {
    padding-top: 50px;
  }
}

.know .specialGrid {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.know .specialGrid .item {
  display: flex;
  align-self: center;
  justify-content: center;
  flex-direction: column;
  border: 10px solid #fff;
  padding: 15px;
  color: #fff !important;
  width: 25%;
  text-align: center;
  height: 350px;
  box-sizing: border-box;
}

@media (min-width: 576px) {
  .know .specialGrid .item {
    justify-content: center;
    height: 360px;
  }
}

@media (min-width: 1440px) {
  .know .specialGrid .item {
    height: 400px;
  }
}

.know .specialGrid .item a {
  text-decoration: none !important;
  color: inherit !important;
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 5;
}

.know .specialGrid .item a:hover {
  text-decoration: none !important;
  color: inherit;
}

.know .specialGrid .item h4 {
  font-family: "Amsi Pro XLight";
  font-size: 45px;
  text-transform: uppercase;
}

.know .specialGrid .item h5 {
  font-family: "Amsi Pro XLight";
  text-transform: uppercase;
  padding: 20px 0 15px 0;
  line-height: 25px;
}

.know .specialGrid .item h5 strong {
  font-family: "Amsi Pro Black";
  letter-spacing: .3px;
  padding: 0;
}

.know .specialGrid .item .text {
  padding-bottom: 10px;
}

.know .specialGrid .item .text p {
  font-size: 14px;
}

@media (min-width: 768px) {
  .know .specialGrid .item .text p {
    font-size: 14px;
  }
}

@media (min-width: 1400px) {
  .know .specialGrid .item .text p {
    font-size: 15px;
  }
}

.know .specialGrid .item .iconBox {
  width: 140px;
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

@media (min-width: 576px) {
  .know .specialGrid .item .iconBox {
    width: 150px;
    height: 150px;
  }
}

.know .specialGrid .item .iconBox img {
  width: 140px;
  transition: 1.3s;
}

@media (min-width: 576px) {
  .know .specialGrid .item .iconBox img {
    width: 150px;
  }
}

.know .specialGrid .item:hover .iconBox img {
  transform: rotateY(360deg);
}

.know .specialGrid .item--1 {
  justify-content: center;
  height: 150px;
}

.know .specialGrid .item--1 h4 {
  font-size: 25px;
}

@media (min-width: 576px) {
  .know .specialGrid .item--1 h4 {
    font-size: 35px;
  }
}

@media (min-width: 992px) {
  .know .specialGrid .item--1 h4 {
    font-size: 45px;
  }
}

@media (min-width: 768px) {
  .know .specialGrid .item--1 {
    border-right: 0;
    height: 360px;
  }
}

@media (min-width: 1440px) {
  .know .specialGrid .item--1 {
    height: 400px;
  }
}

.know .specialGrid .item--2 {
  background: url("../images/know_photo1.jpg") no-repeat;
  background-size: cover;
  background-position: center;
  height: 300px;
}

@media (min-width: 768px) {
  .know .specialGrid .item--2 {
    height: 360px;
  }
}

@media (min-width: 1440px) {
  .know .specialGrid .item--2 {
    height: 400px;
  }
}

@media (min-width: 768px) {
  .know .specialGrid .item--2 {
    border-left: 0;
  }
}

.know .specialGrid .item--8 {
  padding: 0;
  justify-content: space-between;
}

.know .specialGrid .item--8 a {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 0;
  justify-content: space-between;
  flex-direction: column;
}

.know .specialGrid .item--8 .photo {
  background: url("../images/know_lobby.jpg") no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 60%;
}

.know .specialGrid .item--8 .text {
  width: 100%;
  height: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.know .specialGrid .item--8 .text h5 {
  padding-left: 15px;
  padding-right: 15px;
}

.newsletter {
  color: #333333;
  position: relative;
  padding-top: 0;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.newsletter span.wpcf7-list-item {
  margin-left: 0;
}

.newsletter h5 {
  font-family: GoudyOlStPL,sans-serif;
  padding-bottom: 20px;
  font-size: 23px;
}

.newsletter p {
  font-size: 11px;
}

@media (min-width: 576px) {
  .newsletter p {
    font-size: 12px;
  }
}

.newsletter label {
  font-size: 10px;
  line-height: 13px;
  color: #333333;
  padding-left: 0;
  cursor: pointer;
  align-items: center;
}

.newsletter .safe {
  color: #333333;
  font-size: 12px;
  padding-top: 14px;
}

@media (min-width: 576px) {
  .newsletter .safe {
    font-size: 14px;
  }
}

.newsletter input[type="text"],
.newsletter input[type="email"] {
  border-radius: 0 !important;
  border: 1px solid #848484;
  color: #848484;
  background-color: #fff;
  height: auto;
  padding: 10px 15px;
  transition: 0.3s;
  margin-bottom: 5px;
  font-size: 12px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-weight: 300;
  text-transform: lowercase;
  font-family: "Montserrat", sans-serif;
}

@media (min-width: 992px) {
  .newsletter input[type="text"],
  .newsletter input[type="email"] {
    font-size: 13px;
  }
}

.newsletter input[type="text"]:focus,
.newsletter input[type="email"]:focus {
  padding-left: 10px;
  box-shadow: none !important;
  border-color: #333333;
}

.newsletter div.wpcf7 .ajax-loader {
  position: absolute;
  margin-top: 8px;
}

.newsletter .wpcf7-form-control-wrap {
  position: static;
  width: 20px;
}

.newsletter .wpcf7-form-control-wrap span.wpcf7-not-valid-tip {
  width: 100%;
  font-size: 12px;
}

.newsletter .wpcf7-response-output.wpcf7-display-none.wpcf7-validation-errors,
.newsletter div.wpcf7-mail-sent-ng,
.newsletter div.wpcf7-aborted {
  border: none;
  color: #f00;
  font-size: 13px;
  text-align: center;
  margin-top: 12px;
  margin-bottom: 0px;
}

.newsletter div.wpcf7-mail-sent-ok {
  border: none !important;
  color: #398f14 !important;
  text-align: center !important;
  font-weight: bold !important;
  margin: 10px 0px !important;
}

.newsletter .btnBox input[type="checkbox"] {
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute;
}

.newsletter .formWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 0;
  margin-top: 30px;
  max-width: 360px;
}

.newsletter .formWrap h5 {
  color: #333333;
  font-size: 30px;
}

.newsletter .formWrap input[type="submit"] {
  color: #333333;
}

.newsletter .agree {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.newsletter div.wpcf7-response-output {
  font-size: 13px;
}

.newsletter .checkAcceptRodo {
  margin-left: 0;
  margin-right: 5px;
}

.listOfferTextSlider .item {
  margin-bottom: 35px;
}

@media (min-width: 992px) {
  .listOfferTextSlider .item {
    margin-bottom: 90px;
  }
}

.listOfferTextSlider .item .photoWrap {
  margin-top: 7px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-clip: content-box;
}

@media (min-width: 768px) {
  .listOfferTextSlider .item .photoWrap {
    margin-top: 0;
  }
}

.listOfferTextSlider .item .photoWrap,
.listOfferTextSlider .item .photoWrap .slide {
  min-height: 220px;
}

@media (min-width: 450px) {
  .listOfferTextSlider .item .photoWrap,
  .listOfferTextSlider .item .photoWrap .slide {
    min-height: 250px;
  }
}

@media (min-width: 576px) {
  .listOfferTextSlider .item .photoWrap,
  .listOfferTextSlider .item .photoWrap .slide {
    min-height: 350px;
  }
}

@media (min-width: 1200px) {
  .listOfferTextSlider .item .photoWrap,
  .listOfferTextSlider .item .photoWrap .slide {
    min-height: 400px;
  }
}

@media (min-width: 1400px) {
  .listOfferTextSlider .item .photoWrap,
  .listOfferTextSlider .item .photoWrap .slide {
    min-height: 450px;
  }
}

.listOfferTextSlider .item .textWrap .textBox {
  margin: 0 auto;
  padding: 15px 0 15px 0;
}

.listOfferTextSlider .item .textWrap .textBox .icon {
  width: 50px;
}

.listOfferTextSlider .item .textWrap .textBox .titleSection {
  padding-top: 0;
  padding-bottom: 0;
  max-width: 600px;
  text-transform: uppercase;
  font-family: "Amsi Pro Black";
  font-size: 22px;
}

@media (min-width: 768px) {
  .listOfferTextSlider .item .textWrap .textBox .titleSection {
    font-size: 24px;
  }
}

@media (min-width: 1200px) {
  .listOfferTextSlider .item .textWrap .textBox .titleSection {
    line-height: 60px;
  }
}

@media (min-width: 1200px) {
  .listOfferTextSlider .item .textWrap .textBox .titleSection.medium {
    line-height: 50px;
  }
}

.listOfferTextSlider .item .textWrap .textBox .boxInfo p {
  font-size: 12px;
}

@media (min-width: 576px) {
  .listOfferTextSlider .item .textWrap .textBox .boxInfo p {
    font-size: 14px;
  }
}

.listOfferTextSlider .item .textWrap .textBox p {
  font-size: 14px;
  text-align: justify;
}

@media (min-width: 1200px) {
  .listOfferTextSlider .item .textWrap .textBox p {
    font-size: 16px;
  }
}

.listOfferTextSlider .item .textWrap .textBox p + p {
  padding-top: 15px;
}

.listOfferTextSlider .item .btnBox {
  padding-top: 30px;
}

.listOfferTextSlider .item .btnBox .linkIcon {
  color: #0f3959;
  font-size: 16px;
  text-transform: uppercase;
  display: flex;
  align-items: flex-end;
  color: #0f3959;
}

@media (min-width: 992px) {
  .listOfferTextSlider .item .btnBox .linkIcon {
    font-size: 18px;
  }
}

.listOfferTextSlider .item .btnBox .linkIcon:hover, .listOfferTextSlider .item .btnBox .linkIcon:active, .listOfferTextSlider .item .btnBox .linkIcon:focus {
  text-decoration: none;
}

.listOfferTextSlider .item .btnBox .linkIcon:hover > img, .listOfferTextSlider .item .btnBox .linkIcon:active > img, .listOfferTextSlider .item .btnBox .linkIcon:focus > img {
  transform: scale(1.02);
}

.listOfferTextSlider .item:nth-of-type(odd) .photoWrap {
  order: 2;
}

.listOfferTextSlider .item:nth-of-type(odd) .textWrap {
  order: 1;
}

@media (min-width: 992px) {
  .listOfferTextSlider .item:nth-of-type(odd) .textWrap .textBox {
    padding-right: 70px;
  }
}

.listOfferTextSlider .item:nth-of-type(odd) .textWrap .textBox .titleSection {
  text-align: left;
  order: 2;
}

.listOfferTextSlider .item:nth-of-type(odd) .textWrap .textBox .head {
  justify-content: flex-start;
}

.listOfferTextSlider .item:nth-of-type(odd) .textWrap .boxInfo {
  margin-right: 20px;
  order: 1;
}

.listOfferTextSlider .item:nth-of-type(odd) .btnBox {
  order: 3;
}

.listOfferTextSlider .item:nth-of-type(even) .photoWrap {
  order: 2;
}

@media (min-width: 768px) {
  .listOfferTextSlider .item:nth-of-type(even) .photoWrap {
    order: 1;
  }
}

.listOfferTextSlider .item:nth-of-type(even) .textWrap {
  order: 1;
}

@media (min-width: 768px) {
  .listOfferTextSlider .item:nth-of-type(even) .textWrap {
    order: 2;
  }
}

@media (min-width: 992px) {
  .listOfferTextSlider .item:nth-of-type(even) .textWrap .textBox {
    padding-left: 70px;
  }
}

.listOfferTextSlider .item:nth-of-type(even) .textWrap .textBox .titleSection {
  text-align: left;
  order: 1;
}

.listOfferTextSlider .item:nth-of-type(even) .textWrap .boxInfo {
  margin-left: 20px;
  order: 2;
}

.listOfferTextSlider .item:nth-of-type(even) .btnBox {
  order: 3;
}

.listOfferTextSlider .owl-theme .owl-dots .owl-dot {
  opacity: 1;
}

.listOfferTextSlider .owl-theme .owl-dots .owl-dot span {
  background-color: #0f3959;
  opacity: .8;
  margin: 0 5px;
}

.listOfferTextSlider .owl-theme .owl-dots .owl-dot.active span,
.listOfferTextSlider .owl-theme .owl-dots .owl-dot:hover span {
  opacity: 1;
}

.listOfferTextSlider .owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 5px;
}

@media (min-width: 768px) {
  .listOfferTextSlider .owl-theme .owl-nav.disabled + .owl-dots {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}

.boxInfo {
  background-color: #e35205;
  display: inline-block;
  margin: 3px 0 8px;
  padding: 15px;
  display: flex;
  align-items: center;
}

.boxInfo p {
  font-size: 24px;
  color: #0f3959;
  font-weight: bold;
}

.specialForm .titleSection {
  padding-bottom: 0;
}

.specialForm h5 {
  padding-bottom: 25px;
  text-transform: uppercase;
  color: #0f3959;
  font-size: 16px;
  text-transform: lowercase;
}

.specialForm label {
  margin-bottom: 0;
  font-size: 12px;
}

@media (min-width: 768px) {
  .specialForm label {
    font-size: 13px;
  }
}

@media (min-width: 1200px) {
  .specialForm label {
    font-size: 14px;
  }
}

.specialForm label:hover {
  cursor: pointer;
}

.specialForm .form-group {
  margin-bottom: 25px;
}

.specialForm input[type="text"],
.specialForm input[type="email"],
.specialForm input[type="tel"],
.specialForm textarea {
  border-radius: 0 !important;
  border: 0;
  border-bottom: 1px solid #1d1d1b;
  color: #1d1d1b;
  background-color: transparent;
  height: auto;
  padding: 5px 5px 5px 0;
  transition: 0.3s;
  margin-bottom: 5px;
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.specialForm input[type="text"]:focus,
.specialForm input[type="email"]:focus,
.specialForm input[type="tel"]:focus,
.specialForm textarea:focus {
  padding-left: 5px;
  box-shadow: none !important;
  border-color: #1d1d1b;
}

.specialForm .checkWrap .form-group {
  margin-bottom: 7px;
}

.specialForm a {
  color: #000;
  text-transform: uppercase;
  text-decoration: underline;
}

.specialForm .agree {
  display: flex;
  text-align: justify;
  font-size: 10px;
}

@media (min-width: 768px) {
  .specialForm .agree {
    font-size: 11px;
  }
}

@media (min-width: 1200px) {
  .specialForm .agree {
    font-size: 11px;
  }
}

.specialForm .agree p {
  font-size: 12px;
}

@media (min-width: 768px) {
  .specialForm .agree p {
    font-size: 13px;
  }
}

@media (min-width: 1200px) {
  .specialForm .agree p {
    font-size: 14px;
  }
}

.specialForm .showFormPanel {
  padding-top: 8px;
  padding-bottom: 15px;
}

.specialForm .showFormPanel .blueLine {
  width: 100%;
  height: 2px;
  background-color: #8e9ba4;
}

.specialForm .showFormPanel img {
  margin-top: -32px;
  position: relative;
  z-index: 3;
}

.specialForm .showFormPanel img:hover {
  cursor: pointer;
}

.specialForm .showFormPanel .showFormText {
  color: #0f3959;
  font-size: 13px;
  margin-top: -6px;
}

.specialForm .formWrap {
  display: none;
}

.specialForm .otherInfo p {
  color: #0f3959;
  font-size: 13px;
}

.specialForm span.wpcf7-not-valid-tip {
  font-size: 13px !important;
  margin-left: 0;
  padding-right: 15px;
  min-width: 150px;
  text-align: left;
}

.specialForm div.wpcf7-validation-errors,
.specialForm div.wpcf7-acceptance-missing {
  border: 0 !important;
  text-align: center;
  color: #ff0000;
}

.specialForm .form-check-input {
  margin-top: -11px;
}

.specialForm .form-check-input:hover {
  cursor: pointer;
}

.specialForm .div.wpcf7-mail-sent-ok {
  color: #398f14;
  border: none;
  text-align: center;
}

div.wpcf7-response-output {
  margin-top: 0 !important;
  margin-bottom: 30px !important;
}

div.wpcf7-validation-errors,
div.wpcf7-acceptance-missing {
  border: 0 !important;
  text-align: center;
  color: #ff0000;
}

.saleContact h5 {
  font-family: "Amsi Pro Black";
  color: #0f3959;
  text-transform: uppercase;
  padding: 15px 0 10px 0;
}

@media (min-width: 576px) {
  .saleContact h5 {
    padding: 30px 0 20px 0;
  }
}

.saleContact .item {
  padding-top: 15px;
  font-size: 14px;
}

.saleContact .item p {
  font-size: 14px;
}

.saleContact .item a {
  color: #212529;
}

.saleContact .item + .item {
  margin-top: 25px;
}

@media (min-width: 576px) {
  .saleContact .item + .item {
    margin-top: 10px;
  }
}

@media (min-width: 992px) {
  .saleContact .item + .item {
    margin-top: 0;
  }
}

.banners {
  padding-top: 35px;
  padding-bottom: 15px;
}

.banners .item {
  height: 250px;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.banners .item:hover .textWrap {
  opacity: 1;
}

.banners .item:hover .title {
  opacity: 0;
}

@media (min-width: 365px) {
  .banners .item {
    height: 350px;
  }
}

.banners .item .title {
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: .4s;
  opacity: 1;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.banners .item .title h5 {
  background-color: #ec6615;
  color: #fff;
  padding: 15px 15px;
  font-size: 18px;
}

@media (min-width: 576px) {
  .banners .item .title h5 {
    padding: 15px 25px;
  }
}

@media (min-width: 768px) {
  .banners .item .title h5 {
    font-size: 20px;
  }
}

.banners .item .textWrap {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.6);
  top: 0;
  left: 0;
  opacity: 0;
  transition: .4s;
}

.banners .item .textWrap .textBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  padding: 25px;
  text-align: center;
}

.banners .item .textWrap .textBox p {
  color: #fff;
}

.banners .item .textWrap .textBox .btnBox {
  margin-top: 20px;
}

.banners .item .textWrap .textBox .btnBox a {
  font-size: 15px;
}

.tableBox {
  margin: 0 auto;
  overflow-x: scroll;
  font-size: 14px;
}

@media (min-width: 992px) {
  .tableBox {
    font-size: 15px;
    overflow-x: hidden;
  }
}

@media (min-width: 1400px) {
  .tableBox {
    font-size: 17px;
  }
}

.tableBox .table {
  text-align: center;
  color: #0f3959;
}

.tableBox .table thead th {
  vertical-align: middle;
}

.tableBox .table thead th p {
  min-height: 50px;
  padding-top: 8px;
  font-size: 13px;
}

@media (min-width: 992px) {
  .tableBox .table thead th p {
    font-size: 14px;
  }
}

.tableBox .table .title {
  font-weight: 600;
  text-align: left;
}

.tableBox .table tbody th {
  vertical-align: middle;
}

.tableBox .table tbody td {
  vertical-align: middle;
  font-size: 15px;
}

@media (min-width: 576px) {
  .tableBox .table tbody td {
    font-size: 16px;
  }
}

@media (min-width: 768px) {
  .tableBox .table tbody td {
    font-size: 17px;
  }
}

@media (min-width: 992px) {
  .tableBox .table tbody td {
    font-size: 18px;
  }
}

.tableBox .table tbody .full {
  background-color: #0f3959;
  text-transform: uppercase;
  padding-top: 19px;
  padding-bottom: 20px;
}

.tableBox .table tbody .leftTitle {
  text-align: left;
  font-family: "Amsi Pro XLight";
}

.tableBox .table tbody .iconWrap {
  width: 50px;
  text-align: right;
  margin-left: auto;
  padding-left: 8px;
}

@media (min-width: 992px) {
  .tableBox .table tbody .iconWrap {
    width: 70px;
    margin-right: 20px;
  }
}

.tableBox .table tbody .iconWrap img {
  width: 50px;
}

@media (min-width: 992px) {
  .tableBox .table tbody .iconWrap img {
    width: 70px;
  }
}

.table-bordered, .table-bordered td, .table-bordered th {
  border-color: #0f3959;
}

.table thead th {
  border-bottom: 1px solid #0f3959;
}

.table td, .table th {
  padding: .99rem .5rem;
}

@media (min-width: 576px) {
  .table td, .table th {
    padding: .99rem .75rem;
  }
}

.wptripadvisor_t1_A_8 {
  display: none;
}

.wptripadvisor_t1_SPAN_5,
#wprev-slider-1 .wprev_preview_bg1_T1::after {
  display: none;
}

#wprev-slider-1 .wprev_preview_tcolor2_T1 {
  display: none !important;
}

a.wprs_rd_more {
  font-weight: bold;
}

.hotel-slider .overlay {
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.hotel-slider .hotel-slider__slide {
  padding: 10px;
}

.hotel-slider .hotel-slider__slide .hotel-slider__media {
  position: relative;
}

.hotel-slider .hotel-slider__slide .hotel-slider__media img {
  height: 350px;
}

@media (min-width: 576px) {
  .hotel-slider .hotel-slider__slide .hotel-slider__media img {
    height: 500px;
  }
}

@media (min-width: 992px) {
  .hotel-slider .hotel-slider__slide .hotel-slider__media img {
    max-height: 500px;
  }
}

@media (min-width: 1500px) {
  .hotel-slider .hotel-slider__slide .hotel-slider__media img {
    height: auto;
    max-height: 600px;
  }
}

.hotel-slider .hotel-slider__slide.slick-center.slick-center .overlay {
  background-color: transparent !important;
}

.hotel-slider .slick-arrow {
  position: absolute;
  z-index: 2000;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  background-color: transparent;
  border: 0;
  outline: 0;
  font-size: 0;
  cursor: pointer;
  opacity: .7;
  transition: .2s;
  background-size: cover;
}

.hotel-slider .slick-arrow::before {
  content: "" !important;
}

@media (min-width: 576px) {
  .hotel-slider .slick-arrow {
    width: 62px;
    height: 62px;
  }
}

.hotel-slider .slick-arrow:hover {
  opacity: 1;
}

.hotel-slider .slick-arrow.slick-prev {
  left: 3%;
  background-image: url("../images/slider-left.png");
}

@media (min-width: 576px) {
  .hotel-slider .slick-arrow.slick-prev {
    left: 10%;
  }
}

.hotel-slider .slick-arrow.slick-next {
  right: 3%;
  background-image: url("../images/slider-right.png");
}

@media (min-width: 576px) {
  .hotel-slider .slick-arrow.slick-next {
    right: 10%;
  }
}

.opinions-slider {
  /* the slides */
  /* the parent */
}

.opinions-slider-item {
  padding: 20px 0;
}

@media (min-width: 992px) {
  .opinions-slider-item {
    padding: 20px 0;
  }
}

.opinions-slider-item__text {
  text-align: justify;
  padding: 15px 0;
  max-width: 930px;
  margin: 0 auto;
}

@media (min-width: 1200px) {
  .opinions-slider-item__text p {
    font-size: 16px;
  }
}

.opinions-slider-item__author {
  padding-top: 15px;
  padding-bottom: 20px;
}

.opinions-slider .slick-dots li,
.opinions-slider .slick-dots li button {
  width: 8px;
}

.opinions-slider .slick-dots li button:before {
  color: #878787;
  font-size: 45px;
}

.opinions-slider .slick-slide {
  margin: 0 20px;
}

.opinions-slider .slick-list {
  margin: 0 -20px;
}

.iconsColorWrap .item {
  height: 230px;
  background-color: #0f3959;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.iconsColorWrap .item__icon {
  height: auto;
  max-width: 100px;
  max-height: 100px;
  transition: 1.3s;
}

.iconsColorWrap .item__title {
  text-transform: uppercase;
  color: #fff;
  font-size: 16px;
  text-align: center;
  padding: 20px 0;
  font-weight: 600;
}

.iconsColorWrap .item--1 {
  background-color: #0f3959;
}

.iconsColorWrap .item--3 {
  background-color: #0f3959;
}

.iconsColorWrap .item--3 .item__icon {
  margin-top: 40px;
  max-width: 155px;
  max-height: 50px;
}

.iconsColorWrap .item--3 .item__title {
  color: #0f3959;
}

.iconsColorWrap .item--4 {
  background-color: #0f3959;
}

.iconsColorWrap .item--6 {
  background-color: #0f3959;
}

.iconsColorWrap .item:hover .item__icon {
  transform: rotateY(360deg) !important;
}

.reservation {
  padding-top: 0;
}

.iframe-loading {
  background: url("../images/preloader.gif") center 50px no-repeat;
}

@media (min-width: 1200px) {
  .youAbousUsPage .opinionsWrap img {
    margin-right: -75px;
    margin-left: auto;
    display: block;
  }
}

@media (min-width: 1400px) {
  .youAbousUsPage .opinionsWrap img {
    margin-right: -100px;
    margin-left: auto;
    display: block;
  }
}

.pageSpecialOffers .specialOffersHome .firstCol {
  border-right: unset;
}

@media (min-width: 768px) {
  .pageRooms .offerRooms .item:nth-child(even) .photoWrap {
    order: 2;
  }
}

.pageRooms .offer {
  padding-bottom: 0;
}

.pageRooms .tryApartments.rooms {
  background-image: unset;
  padding-bottom: 50px;
}

@media (min-width: 1200px) {
  .pageRooms .tryApartments.rooms {
    padding-bottom: 75px;
  }
}

.pageRooms .tryApartments.rooms .item {
  height: 50vh;
  min-height: 500px;
  max-height: 600px;
  overflow: hidden;
}

.pageRooms .tryApartments.rooms .item > a {
  display: block;
  z-index: 2;
  position: absolute;
  width: 100%;
  height: 100%;
}

.pageRooms .tryApartments.rooms .item--1:hover button, .pageRooms .tryApartments.rooms .item--1:active button, .pageRooms .tryApartments.rooms .item--1:focus button, .pageRooms .tryApartments.rooms .item--2:hover button, .pageRooms .tryApartments.rooms .item--2:active button, .pageRooms .tryApartments.rooms .item--2:focus button {
  top: 30px;
  bottom: unset;
}

.pageRooms .tryApartments.rooms .item--1 button, .pageRooms .tryApartments.rooms .item--2 button {
  top: 30px;
  left: 20px;
  bottom: unset;
}

@media (min-width: 768px) {
  .pageRooms .tryApartments.rooms .item--1 button, .pageRooms .tryApartments.rooms .item--2 button {
    top: -100px;
    bottom: unset;
  }
}

.pageRooms .tryApartments.rooms .item--3:hover button, .pageRooms .tryApartments.rooms .item--3:active button, .pageRooms .tryApartments.rooms .item--3:focus button, .pageRooms .tryApartments.rooms .item--4:hover button, .pageRooms .tryApartments.rooms .item--4:active button, .pageRooms .tryApartments.rooms .item--4:focus button {
  bottom: 50px;
  top: unset;
}

.pageRooms .tryApartments.rooms .item--3 button, .pageRooms .tryApartments.rooms .item--4 button {
  bottom: 50px;
  left: 20px;
  top: unset;
}

@media (min-width: 768px) {
  .pageRooms .tryApartments.rooms .item--3 button, .pageRooms .tryApartments.rooms .item--4 button {
    bottom: -100px;
    top: unset;
  }
}

.pageRooms .tryApartments.rooms .item .title {
  width: 200px;
  height: 200px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 12;
  margin: 0;
}

@media (min-width: 768px) {
  .pageRooms .tryApartments.rooms .item .title {
    width: 210px;
    height: 210px;
  }
}

@media (min-width: 1200px) {
  .pageRooms .tryApartments.rooms .item .title {
    width: 220px;
    height: 220px;
  }
}

.pageRooms .tryApartments.rooms .item .title h3 {
  color: #000;
  font-size: 35px;
}

@media (min-width: 768px) {
  .pageRooms .tryApartments.rooms .item .title h3 {
    font-size: 40px;
  }
}

@media (min-width: 1200px) {
  .pageRooms .tryApartments.rooms .item .title h3 {
    font-size: 50px;
  }
}

@media (min-width: 1400px) {
  .pageContact .location {
    padding-top: 60px;
    padding-bottom: 0;
  }
}

.pageContact .mainContent .titleSection {
  text-transform: uppercase;
}

.pageContact .mainContent .item .textWrap .textBox a {
  color: #000;
}

.pageContact .mainContent .item .icon {
  width: 50px;
}

.pageContact .mainContent .item .icon img {
  width: 50px;
}

.pageContact .mainContent .item .text p {
  padding-top: 2px !important;
  text-align: left !important;
  font-size: 15px !important;
}

.pageContact .mainContent .item .text p + p {
  padding-top: 2px;
}

.pageContact .mainContent .formContact .formWrap {
  display: block;
}

.pageGallery #filters {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.pageGallery #filters .button {
  color: #0f3959;
  margin: 4px 15px;
  min-height: 35px;
  font-size: 16px;
}

@media (min-width: 992px) {
  .pageGallery #filters .button {
    font-size: 22px;
    margin: 8px 15px;
  }
}

@media (min-width: 1200px) {
  .pageGallery #filters .button {
    font-size: 25px;
  }
}

.pageGallery #filters .button::after {
  content: '';
  opacity: 0;
  width: 100%;
  height: 2px;
  background-color: #0f3959;
  margin-top: 3px;
  transition: .3s;
  display: block;
}

.pageGallery #filters .button.activeItem::after {
  opacity: 1;
}

.pageGallery #filters .button:hover {
  cursor: pointer;
}

.pageGallery .grid {
  min-height: 500px;
}

.pageGallery .grid .element-item {
  margin-bottom: 20px;
  height: auto;
  position: relative;
  overflow: hidden;
  height: 300px;
}

@media (max-width: 768px) {
  .pageGallery .grid .element-item {
    width: 100%;
  }
}

@media (min-width: 576px) {
  .pageGallery .grid .element-item {
    width: 350px;
  }
}

@media (min-width: 1200px) {
  .pageGallery .grid .element-item {
    width: 400px;
  }
}

@media (min-width: 1400px) {
  .pageGallery .grid .element-item {
    width: 440px;
  }
}

.pageGallery .grid .element-item img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  min-width: 100%;
  min-height: 100%;
  margin: 0 20px;
}

.pageConference .know {
  background-color: #fff;
  padding-bottom: 0;
}

.pageConference .know .iconsWrap .item {
  height: 200px;
  background-color: #0f3959;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pageConference .know .iconsWrap .item__icon-box {
  height: 120px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pageConference .know .iconsWrap .item__icon {
  height: auto;
  max-width: 100px;
  max-height: 100px;
}

.pageConference .know .iconsWrap .item__title {
  text-transform: inherit;
  color: #fff;
  font-size: 15px;
  text-align: center;
  padding: 15px 0 3px 0;
}

.pageConference .know .iconsWrap .item--1 {
  background-color: #0f3959;
}

.pageConference .know .iconsWrap .item--1 .item__icon {
  margin-top: 20px;
}

.pageConference .know .iconsWrap .item--2 {
  background-color: #0f3959;
}

.pageConference .know .iconsWrap .item--2 .item__icon {
  height: auto;
  max-width: 100px;
  max-height: 100px;
  margin-top: 20px;
}

.pageConference .know .iconsWrap .item--2 .item__title {
  color: #0f3959;
}

.pageConference .know .iconsWrap .item--3 {
  background-color: #0f3959;
}

.pageConference .know .iconsWrap .item--3 .item__icon {
  height: auto;
  max-width: 100px;
  max-height: 100px;
}

.pageConference .know .iconsWrap .item--4 {
  background-color: #0f3959;
}

.pageConference .know .iconsWrap .item--6 {
  background-color: #0f3959;
}

.pageSingleOffer .specialOffersHome .firstCol {
  border-right: unset;
}

.hotelsList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #474747;
}

@media (min-width: 1050px) {
  .hotelsList {
    position: relative;
    z-index: 10;
  }
}

.hotelsList .item {
  width: 100%;
  margin: 1%;
}

@media (min-width: 576px) {
  .hotelsList .item {
    width: 48%;
    margin: 1%;
  }
}

@media (min-width: 768px) {
  .hotelsList .item {
    width: 32%;
    margin: .5%;
  }
}

@media (min-width: 992px) {
  .hotelsList .item {
    width: 24%;
    margin: .5%;
  }
}

@media (min-width: 1200px) {
  .hotelsList .item {
    width: 19%;
    margin: .5%;
  }
}

.hotelsList .item img {
  transition: .3s;
}

.hotelsList .item:hover {
  z-index: 222;
}

.hotelsList .item:hover img:hover {
  cursor: pointer;
  transform: scale(1.17);
  position: absolute;
  z-index: 222222222;
  box-shadow: 0 20px 50px rgba(236, 102, 21, 0.7);
}

.hotelsList .item:nth-child(odd):hover img:hover {
  cursor: pointer;
  transform: scale(1.17);
  position: absolute;
  z-index: 222222222;
}
