.reservationPanelWrap--slim{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display:none;

    @media (min-width:1050px){
        display:block;
    }

    .reservationPanel{
        padding: 0;
    }
}

.reservationPanelWrap{
    &.fixed{
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        display:none;

        @media (min-width:1050px){
            display:block;
        } 

        h5{
            display:none;
        }

        .reservationPanel{
            padding:0;

            .form-group{
                margin-top:0 !important;
            }
        }
        .reservationPanel .-form button{
            margin-top:8px !important;
            margin-bottom:8px !important;
            padding-top:7px !important;
            padding-bottom:7px !important;
        }

    }
}