.sidebarOtherPosts{
    border: 2px solid #e6e6e6;
    display: block;
    padding: 55px 15px 40px;

    .item{
        text-align: center;
        width: 200px !important;   

        //border-bottom:1px solid #eb8d4d;
        border-bottom:1px solid $orange;
        //margin:20px auto;
        margin: 0px auto 20px auto;
        padding: 10px 5px 30px 5px;

        a{
            color:#333333;
        }

        .date{
            font-size:10px;
            font-family: "Montserrat", sans-serif;
            padding-bottom:3px;
            text-transform: uppercase;
        }

        .title{
            font-family: 'GoudyOlStPL';
        }

    }
}


.slick-vertical .slick-slide{
    display: flex;
    justify-content: center;
}

.slick-vertical .slick-next, 
.slick-vertical .slick-prev{
    right:unset;
    left:50%;
    transform:translateX(-50%);

    &:before{
        content:"";
    }
}

.slick-vertical .slick-next{
    top:-35px;
    background-image: url('../images/vertical_arrow_top.png');
    width: 44px;
    height: 24px;
}

.slick-vertical .slick-prev{
    top:unset;
    bottom:-25px;
    background-image: url('../images/vertical_arrow_bottom.png');
    width: 44px;
    height: 24px;
}