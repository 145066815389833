.welcome {
  background-repeat: no-repeat;
  background-position: right 20%;

  @media (min-width: 992px) {
    background-image: url("../images/welcome_bg_element.jpg");
  }

  .area {
    font-size: 36px;
    font-family: Lato, sans-serif;
    font-weight: bold;
    color: $blue;
  }

  .info {
    text-transform: uppercase;
  }
}
