$breakpoints: (
  xsmall: (
    min-width: 460px,
  ),
  small: (
    min-width: 576px,
  ),
  medium: (
    min-width: 768px,
  ),
  large: (
    min-width: 992px,
  ),
  xlarge: (
    min-width: 1200px,
  ),
  xxlarge: (
    min-width: 1440px,
  ),
);

@mixin media($breakpoint) {
  $size: map-get($breakpoints, $breakpoint);
  @if ($size) {
    @media #{$size} {
      @content;
    }
  } @else {
    @error '"#{$breakpoint}" - błąd, nie wiem co to';
  }
}

//uzycie
// @include media(xsmall) {
//     font-size:18px;
// }

@mixin headFontLight {
  font-family: "Amsi Pro XLight";
}

@mixin headFontMid {
  font-family: "Amsi Pro";
}

@mixin headFontBold {
  font-family: "Amsi Pro Black";
}

@mixin goudyFont{
  font-family:"GoudyOlStPL", sans-serif;
}

@mixin on-event($self: false) {
  @if $self {
    &,
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  } @else {
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  }
}

@mixin overlay($bgColor: #000, $bgOpacity: 0.6) {
  .overlay {
    background: rgba($bgColor, $bgOpacity);
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
}

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin positionCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 12;
  margin: 0;
}

@mixin buttonTopToDown {
  @include on-event {
    button {
      top: 30px;
      bottom: unset;
    }
  }
  button {
    top: 30px;
    left: 20px;
    bottom: unset;

    @media (min-width: 768px) {
      top: -100px;
      bottom: unset;
    }
  }
}

@mixin buttonDownToTop {
  @include on-event {
    button {
      bottom: 50px;
      top: unset;
    }
  }

  button {
    bottom: 50px;
    left: 20px;
    top: unset;

    @media (min-width: 768px) {
      bottom: -100px;
      top: unset;
    }
  }
}
