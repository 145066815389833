.specialOffers{
    color:$darkBlue;

    a{
        color:$darkBlue;

        .hvr-ripple-out:before{
            //background-color:$orange;
            border: 6px solid $orange;
            animation-duration: .7s;
        }

        @include on-event{
            text-decoration:none;
            color:$darkBlue !important;

            .photo .psuedo-background-img{
                transform:scale(1.03);
            }

            .hvr-ripple-out:before, .hvr-ripple-out:before, .hvr-ripple-out:before {
                -webkit-animation-name: hvr-ripple-out;
                animation-name: hvr-ripple-out;
              }

        }

    }
    .item{

        border-bottom:2px solid $orange;
        //padding-top:5px;
        margin-top:25px !important;

        

        .titleOffer{

            padding-bottom:10px;
            font-size:18px;
            @include headFontMid;

            @media(min-width:768px){
                font-size:22px;
            }

            @media(min-width:992px){
                font-size:24px;
                padding-bottom:10px;
            }

            strong{
                @include headFontBold;
            }
        }
        
        .infoBox{
            .photo{
                height: 190px;
                overflow:hidden;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                position:relative;

                .psuedo-background-img{
                    transition:.25s;
                }

                @media(min-width:400px){
                    height: 230px;
                }

                @media(min-width:576px){
                    height: 280px;
                }

                @media(min-width:768px){
                    height: 400px;
                }
    
                @media(min-width:992px){
                    height: 260px;
                }

                @media(min-width:1200px){
                    height: 300px;
                }

            }
            .panel{
                background-color: #e7e6e5;
                

                .elementWrap{
                    padding-top:8px;
                    padding-bottom:8px;
                    padding-left:5px;
                    padding-right:5px;
                    min-height: 119px;
                    

                    @media(min-width:350px){
                        padding-left:10px;
                        padding-right:10px;
                    }

                    @media(min-width:400px){
                        padding-top:15px;
                        padding-bottom:15px;
                    }

                    @media(min-width:576px){
                        padding-left:25px;
                        padding-right:25px;
                       
                    }

                    p{

                        font-weight: 500;
                        font-size:14px;
                        line-height: 23px;
                        

                        @media(min-width:350px){
                            font-size:14px;
                        }

                        @media(min-width:576px){
                            font-size:14px;
                        } 

                        @media(min-width:768px){
                            max-width: 80%;
                        }
                    }

                    .element{ // do usuniecia

                        font-weight: 500;
                        font-size:14px;

                        @media(min-width:350px){
                            font-size:15px;
                        }

                        @media(min-width:576px){
                            font-size:18px;
                        }
                    }

                    &.centerCol{
                        border-right:1px solid $blue;
                        padding-left:40px;
                        padding-top:20px;
                    }

                }
                
            }

            .btnBox{
                //padding:30px 0;
                
                .arrow{
                    width:57px;
                    height:57px;
                    background-color: $orange;
                    background-image:url('../images/offers_arrow.png');
                    background:url('../images/offers_arrow.png') no-repeat top 50% left 50% $orange;
                    margin-left:auto;
                    display:block;
                }

                @media(min-width:768px){
                    margin-top:-30px;
                }
            }

            .textWrap{
                font-family: 'Open Sans', sans-serif;
                padding-top:10px;
                line-height: 25px;

                .downIcon{
                    img{
                        max-width:50px;

                        @media(min-width:768px){
                            max-width:60px;
                        }

                        @media(min-width:992px){
                            max-width:77px;
                        }

                        &:hover{
                            cursor:pointer;
                        }
                    }
                }

                .mainInfo,
                .moreInfo{
                    font-size:15px;

                    @media(min-width:576px){
                        font-size:15px;
                    }

                    @media(min-width:768px){
                        font-size:16px;
                    }

                    @media(min-width:992px){
                        font-size:17px;
                        min-height:175px;
                    }


                    a{
                        color:$blue;
                    }

                    table{
                        font-size:12px;
                        color:$blue;

                        @media(min-width:576px){
                            font-size:14px;
                        }

                        @media(min-width:768px){
                            font-size:15px;
                        }

                        @media(min-width:992px){
                            font-size:15px;
                        }

                        td, th{
                            padding: .2rem;
                        }

                        @media(min-width:576px){
                            padding: .75rem;
                        }

                    }

                }

                .mainInfo{
                    text-align: justify;
                }

                .moreInfo{
                    padding-top:15px;
                    display:none;
                }

                &.active{
                    .downIcon{
                        img{
                            transform:rotate(180deg);
                        }
                    }
                }
            }

            .panelBottom{
                padding-top:10px;
                .btnBox{
                    width:50%;
                }
                .offerBox{
                    a{  
                        font-size:13px;
                        color:$blue; 
                        text-transform:uppercase;
                        
                        @media(min-width:576px){
                            font-size:14px;
                        }    

                        svg{
                            width:35px;
                            height:auto;
                            margin-left:7px;
                            fill:$blue;

                            @media(min-width:576px){
                                margin-left:10px;
                                width:40px;
                            }
                        }
                    }
                }
            }

            

        }

        

    }
}






