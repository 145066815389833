.apartsList {
  background-color: #efece8;
  .listItem {
    .title {
      padding-top: 30px;
      padding-bottom: 30px;
    }

    .photo {
      position: relative;
      width: 270px;
      height: 270px;

      @media (min-width: 320px) {
        width: 300px;
        height: 300px;
      }
    }
  }
}
