.group{
    background-color:#dddddd;
    padding-top:90px;
    padding-bottom:130px;

    &--specialOffer{
        background-color:#e6e6e6;
    }
}

.groupSlider{
    background-color:#fff;
    margin-top:25px;
    -webkit-box-shadow: 0px 0px 46px -20px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 46px -20px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 46px -20px rgba(0,0,0,0.75);

    .item{
        article{
            padding:30px;
            background-color:#fff;
        
            h5{
                font-size:24px;
                @include goudyFont;
            }
            
            .text{
                padding-top:15px;
                padding-bottom:15px;
                p{
                    font-size:12px;
                }
            }

            .photoBox{
                @media(max-width:768px){
                    margin:25px 0;
                }
                img{
                    width:auto;
                }
            }
        }

        & +.item{
            border-left:2px solid #e7e7e7;
        }
    }

    .owl-item+.owl-item{
        border-left:2px solid #e7e7e7;
    }
}


.groupSlider.owl-theme .owl-nav {
    width: 100%;
    max-width: 1630px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: space-between;

    height: 0;
    margin-top: -40px;
  }

  .groupSlider.owl-carousel .owl-nav button.owl-prev,
  .groupSlider.owl-carousel .owl-nav button.owl-next {
  width: 15px;
  height: 17px;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin: 20px 5px;

  @media (min-width: 576px) {
    margin: 20px;
  }

  @media (min-width: 992px) {
    width: 24px;
    height: 44px;
  }

  &:hover {
    background-color: transparent;
  }
}

.groupSlider.owl-carousel .owl-nav button.owl-prev {
  background-image: url("../images/owl-left-gray.png");
  margin-left:-50px;
}

.groupSlider.owl-carousel .owl-nav button.owl-next {
  background-image: url("../images/owl-right-gray.png");
  margin-right:-50px;
}