.navbar-nav{
    .dropdown-menu{
        background-color:rgba(71, 71, 71, .7);
        position: absolute;
        max-width: unset;
        

        .nav-link{
            
            font-size:14px !important;
        }
        
    }
}

//hotelsDropdown
.navbar-nav{
    .hotelsDropdown{
        .dropdown-menu{
            background-color:rgba(71, 71, 71, .7);
            position: absolute;
            max-width: unset;
            min-width: 97vw;

            @media(min-width:1070px){
                min-width:1000px;
            }
    
            .nav-link{
                display:inline-block !important;
                font-size:13px !important;
                text-align: left !important;
                text-transform: unset !important;
                padding: 9px 15px !important;
                max-width: unset !important;
                width: 33% !important;
                
                @media(min-width:1070px){
                    width: 330px !important;
                }

                
            }
        }
    }
}




.nav-item.dropdown {

    &::after{
        display: inline-block;
        width: 0;
        height: 0;
        margin-left: .255em;
        vertical-align: .255em;
        content: "";
        border-top: .3em solid;
        border-right: .3em solid transparent;
        border-bottom: 0;
        border-left: .3em solid transparent;
        position: absolute;
        right: 6px;
        top: 48%;
        color: #fff;
    }
    
}