footer {
  position:relative;
  z-index: 10;  
  background-color:#fff;

  .footerTop{
    background-color:#ebebeb;
    padding-top: 20px;
    padding-bottom: 20px;

    .logo{
      margin-left:2px;
      img{
        width:145px;
      }
    }

    .nav{
      .nav-link{
        font-size:14px;
        color: #333;
        padding: 10px;
        text-transform: uppercase;
        font-weight: 300;

        &::before{
          display:none;
        }

        @include on-event{
          text-decoration:underline;

          &::before{
            display:none;
          }
        }
      }
    }

    .socialIcons{
      img{
        width:22px;
        height:22px;
      }
    }

    .centerCol{
      display:flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      border-left:1px solid #c2c2c2;
      border-right: 1px solid #c2c2c2;
    }

  }

  /*footer bottom*/
  .copyBox {
    padding-bottom:100px;
    background-color:#fff;
    text-align: center;
    color: #000;
    margin-top: 10px;

    .copyBoxInner {
      padding-top: 10px;
      padding-bottom: 15px;

      p {
        font-size: 12px;
        @media (min-width: 576px) {
          font-size: 14px;
        }
      }
    }
  }
  /* ./footer bottom*/
}
