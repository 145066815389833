.header {
    position:fixed;
    width:100%;

    .headerTop{
        padding-top:10px;
        padding-bottom:5px;
        transition:.3s;
    }

    .headerBottom {
        .navbar{
            transition:.3s;
        }
    }

    .mainLogoColor{
        display:none;
    }

    &.headerSimple{
        background-color:#e9e9e9;

        .headerTop{
            padding-top:0;
            padding-bottom:0;
        }

        .headerBottom {
            .navbar{
                margin-top:0;

                .navbar-nav{
                    .nav-item{
                        .nav-link{
                            @media(min-width:1350px){
                                padding: 4px 5px;
                                font-size:13px;           
                              }                    
                    
                              @media(min-width:1500px){
                                padding: 4px 20px;
                                font-size:13px;                   
                              }
                        }
                    }
                }
            }
        }
        
        .navbar-brand {
            padding-top:0;
            &__logo {
                transform:scale(.7);
            }
        }

        .mainLogoColor{
            display:block;
        }

        .mainLogoWhite{
            display:none;
        }

        

    }
}