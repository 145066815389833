.specialScrollMobile{
    padding-top:0;
    padding-bottom:0;
    overflow:unset;

    .item{
        height:70vh;
        width:100%;
        padding-left:20px;
        padding-right: 20px;
        display:flex;
        justify-content: center;
        align-items:center;
        
        &:nth-child(even){
            background-color:#e8e8e8;
        }
        
        .content{
            margin-left:auto;

            .titleSection{
                border-bottom:2px solid #cccccc;
                padding-bottom:7px;
            }

            .subTitle{
                padding-top:5px;
                padding-bottom:70px;
            }

            .text{
                max-width: 428px;
                margin:0 auto;
                text-align: right;
                letter-spacing: 1px;
                line-height: 28px;
            }
        }
    }
    .photoWrap{
        // height:100%;
        // position:relative;
        .photo{
            height:70vh;
            width:100%;
            background-size:cover;
            background-position: center;
            position:static;
            top:0;
            background-image:url('../images/scroll1.jpg');

            &--1{
                background-image:url('../images/scroll1.jpg');
            }

            &--2{
                background-image:url('../images/scroll2.jpg');
            }

            &--3{
                background-image:url('../images/scroll3.jpg');
            }

            &--4{
                background-image:url('../images/scroll4.jpg');
            }
        }
    }
}