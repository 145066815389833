.hotel-slider {
    
    @include overlay(#000, .4);
    

    .hotel-slider__slide {
        
        padding:10px;
       
        

        .hotel-slider__media{
            position: relative;
            img{
                height:350px;

                @media(min-width:576px){
                    height:500px;
                }

                @media(min-width:992px){
                    max-height:500px;
                }

                @media(min-width:1500px){
                    height:auto;
                    max-height: 600px;
                }
            }
        }

        &.slick-center{
            &.slick-center{
                .overlay{
                    background-color: transparent !important;
                }
            }
        }

    }

}

//slick arrow

.hotel-slider{
    .slick-arrow{
        position: absolute;
        z-index: 2000;
        top:50%;
        transform:translateY(-50%);
        width:40px;
        height:40px;
        background-color:transparent;
        border:0;
        outline:0;
        font-size:0;
        cursor:pointer;
        opacity:.7;
        transition:.2s;
        background-size:cover;

        &::before{
            content:"" !important;
        }

        @media(min-width:576px){
            width:62px;
            height:62px;
        }
        
        &:hover{
            opacity: 1;
        }

        &.slick-prev{
            left:3%;
            background-image: url('../images/slider-left.png');
            @media(min-width: 576px){
                left:10%;
            }
        }

        &.slick-next{
            right:3%;
            background-image: url('../images/slider-right.png');
            @media(min-width: 576px){
                right:10%;
            }
        }
    }
    
}