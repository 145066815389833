.textSection {
  background-image: url("../images/textSection_bg_element1.jpg");
  background-repeat: no-repeat;
  background-position: bottom right;
  padding-bottom: 100px;
  background-size: 300px;

  @media (min-width: 768px) {
    padding-bottom: 155px;
    background-size: unset;
  }
}
