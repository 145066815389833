.promoInfo{
    position:absolute;
    background-color:$orange;
    color:#fff;
    right:0;
    bottom:120px;
    z-index: 3;
    border-left:1px solid #8a4829;
    padding:13px 18px;
    transition:.4;

    @media(min-width:768px){
        bottom:225px;
    }

    .icon{
        padding-right:12px;
        border-right:1px solid #f6b38a;
    }

    .text{
        padding-left:12px;
        line-height: 19px;
        font-family: 'GoudyOlStPL';
    }

    
}