.logosSlider{
    padding: 10px 30px;
    background-color:#474747;

    @media(min-width:768px){
        padding: 10px 50px;
    }

    @media(min-width:1200px){
        padding: 10px 50px;
    }

    .item{
        width: 155px;
        height: 60px;
        padding:20px;
        display:flex;
        justify-content: center;
        align-items: center;
        

        img{
            width:auto;
            transform: scale(.6);
        }

        @media(min-width:768px){
            width: 200px;
            height: 80px;
        }
    }
}