.slideNav {
  background-color: rgba(71,71,71,1);
  position: fixed;
  height: 100vh;
  top: 0;
  right: -500px;
  width: 100%;
  max-width: 500px;
  z-index: 100;
  //overflow-y: scroll;
  overflow-y: auto;
  transition: 0.4s;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: 450px) {
    max-width: 325px;
  }

  @media (min-width: 1200px) {
    max-width: 400px;
  }

  @media (min-width: 1600px) {
    max-width: 500px;
  }

  @media (min-width: 1900px) {
    max-width: 550px;
    right: -551px;
  }

  @media (min-width: 2000px) {
    right: -601px;
    max-width: 600px;
  }

  &.active {
    right: 0;
  }

  .rightPanel {
  }

  .navbar {
    @include flex-center;

    
    

    .navbar-nav {

      @media(max-width:768px){
        overflow-y: scroll;
        //overflow-y: auto;
        height: 65vh;
      }

      .dropdown-menu{
        width: auto;
        position: static;
        border: none;
        
        .nav-link {
            display:block !important; 
        }

        .hvr-underline-from-center:before{
          height:1px;     
        }
      }

      .nav-item {

        &.dropdown{
          &::after{
            top: 18px;

            @media(min-width:992px){
              top: 22px;
            }

            @media(min-width:1200px){
              top: 25px;
            }
          }
        }

        .nav-link {
          font-family: Montserrat,sans-serif;
          font-weight:300;
          color: #fff;
          padding: 5px;
          text-transform: uppercase;
          display:block;

          font-size: 22px;

          @include media(medium) {
            font-size: 23px;
          }

          @include media(large) {
            font-size: 24px;
            margin:2px 0;
          }

          @include media(xxlarge) {
            font-size: 25px;
          }

          // @media (max-width:768px){
          //   max-width: 80%;
          // }
        }
      }
    }
  }
}
