.dom {
  padding-top: 0;
  border-bottom: 40px solid #e5d9c6;
  padding-bottom: 0;
  background-color: #fff;

  @media (min-width: 768px) {
    border-bottom: 0;
  }

  .titleSection.first {
    @include media(xxlarge) {
      font-size: 72px;
    }
  }

  .leftCol {
    background-color: #e5d9c6;

    padding-top: 40px;
    padding-bottom: 40px;

    @media (min-width: 768px) {
      padding-top: 120px;
      padding-bottom: 120px;
    }

    text-align: center;

    img {
      position: relative;
      z-index: 10;

      @media (min-width: 768px) {
        margin-left: 3%;
      }

      @media (min-width: 1100px) {
        margin-left: 12%;
      }

      @media (min-width: 1200px) {
        margin-left: 15%;
      }

      @media (min-width: 1300px) {
        margin-left: 20%;
      }

      @media (min-width: 1400px) {
        margin-left: 25%;
      }

      @media (min-width: 1500px) {
        margin-left: 30%;
      }

      @media (min-width: 1650px) {
        margin-left: 35%;
      }

      @media (min-width: 1800px) {
        margin-left: 40%;
      }
    }
  }

  .rightCol {
    background-image: url("../images/dom_bg_element.jpg");
    background-position: 90% 90%;
    background-repeat: no-repeat;
    padding-top: 25px;
    padding-bottom: 25px;
    border-left: 20px solid #e5d9c6;
    border-right: 20px solid #e5d9c6;

    @media (min-width: 768px) {
      border-left: 10px solid #e5d9c6;
      border-right: 0;
    }

    @include flex-center;

    .content {
      @media (min-width: 1100px) {
        @include flex-center;
      }
      .textWrap {
        max-width: 500px;
        margin: 0 auto;
        padding-top: 25px;
        padding-bottom: 25px;

        p {
          text-align: justify;
        }
      }

      .btnBox {
        padding-top: 25px;
        padding-bottom: 25px;
      }
    }
  }
}
