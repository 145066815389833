.listOfferTextSlider {
    .item {
        margin-bottom: 35px;

        @include media(large){
            margin-bottom: 90px;
        }

        .photoWrap {
            margin-top:7px;

            @media(min-width:768px){
                margin-top:0;
            }

            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            background-clip: content-box;

            &,
            .slide {

                min-height: 220px;

                @media(min-width:450px) {
                    min-height: 250px;
                }

                @media(min-width:576px) {
                    min-height: 350px;
                }

                @media(min-width:1200px) {
                    min-height: 400px;
                }

                @media(min-width:1400px) {
                    min-height: 450px;
                }
            }
        }

        .textWrap {
            .textBox {
                margin: 0 auto;
                padding: 15px 0 15px 0;

                .icon{
                    width:50px;
                    
                }

                .titleSection {
                    padding-top: 0;
                    padding-bottom: 0;
                    max-width: 600px;
                    text-transform: uppercase;

                    @include headFontBold;
                    font-size:22px;

                    @include media(medium){
                        font-size:24px;
                    }

                    @media(min-width:1200px) {
                        line-height: 60px;
                    }

                    &.medium {
                        @media(min-width:1200px) {
                            line-height: 50px;
                        }
                    }
                }

                .boxInfo{
                    p{
                        font-size:12px;
                        @media(min-width:576px){
                            font-size:14px;  
                        }
                    }
                    
                        
                    
                }

                p {
                    font-size: 14px;
                    text-align: justify;

                    @include media(xlarge){
                        font-size: 16px;
                    }

                    &+p{
                        padding-top:15px;
                    }
                }

                
            }

        }

        .btnBox {
            padding-top: 30px;

            .linkIcon{
                color:$blue;
                font-size:16px;
                text-transform: uppercase;
                display: flex;
                align-items: flex-end;
                color:$darkBlue;

                @include media(large){
                    font-size:18px;
                }

                @include on-event{
                    text-decoration: none;

                    & > img {
                        transform:scale(1.02);
                    }
                }
            }
        }

        //item odd (nieparzysty)
        &:nth-of-type(odd) {

            .photoWrap{
                order:2;
            }

            .textWrap {
                    order:1;

                    .textBox {

                        @include media(large){
                            padding-right:70px
                        }

                        .titleSection {
                            text-align: left;
                            order:2;
                        }

                        .head{

                            justify-content: flex-start;

                        }
                    }

                    .boxInfo {
                        margin-right: 20px;
                        order:1;
                    }
                
            }

            .btnBox{
                order:3;
            }

        }

    

    //item even (parzysty)
    &:nth-of-type(even) {
        .photoWrap{
            order:2;

            @media(min-width:768px){
                order:1;
            }
        }

        .textWrap {
                order:1;

                @media(min-width:768px){
                    order:2;
                }
                

                .textBox {

                    @include media(large){
                        padding-left:70px
                    }

                    .titleSection {
                        text-align: left;
                        order:1;
                    }
                    
                    .head{

                            //justify-content: flex-end;
    
                    }
                }

                .boxInfo {
                    margin-left: 20px;
                    order:2;
                }
           
        }

        .btnBox{
            order:3;
        }
    }

    // end item even (parzysty)

    }

    //photoSlider

    .owl-theme .owl-dots .owl-dot {
        opacity: 1;
    }

    .owl-theme .owl-dots .owl-dot span {
        background-color: $darkBlue;
        opacity: .8;
        margin: 0 5px;
    }

    .owl-theme .owl-dots .owl-dot.active span,
    .owl-theme .owl-dots .owl-dot:hover span {
        opacity: 1;
    }

    .owl-theme .owl-nav.disabled+.owl-dots {
        margin-top: 5px;
        
        @media(min-width:768px){
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
        }
        
    }

}