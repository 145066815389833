.specialOffersHome {
  max-width: 1400px;
  padding: 30px 25px 50px 25px;
  margin: 0 auto;
  background-image: url("../images/specialOffersHome_bg.jpg");
  z-index: 5;
  position: relative;

  @include media(medium) {
    padding: 25px;
  }

  @include media(xxlarge) {
    padding: 40px 25px;
  }

  .titleSection {
    color: black;

    @include media(large) {
      font-size: 60px;
    }
  }

  .firstCol {
    padding-top: 30px;
    padding-bottom: 30px;

    @include media(medium) {
      padding-top: 60px;
      padding-bottom: 60px;
    }

    @media (min-width: 768px) {
      border-right: 1px solid black;
    }
  }

  .titleOffer {
    font-size: 24px;
    font-weight: 300;
    font-family: "Lato", sans-serif;

    @include media(xsmall) {
      font-size: 26px;
    }

    @include media(small) {
      font-size: 26px;
    }

    @include media(medium) {
      font-size: 28px;
    }

    @include media(large) {
      font-size: 28px;
    }

    @include media(xxlarge) {
      font-size: 32px;
    }
  }
}
