.fixedHeader {
  .navbar-brand {
    position: fixed;
    top: 0;
    left: -160px;
    background-color: rgba(71,71,71,.1);
    z-index: 56;
    padding: 10px;
    transition: 0.4s;

    @include media(small) {
      padding: 6px 15px 15px 15px;
    }

    &__logo {
      width: 120px;
      height: auto;

      @media (min-width: 576px) {
        width: 125px;
      }

      @media (min-width: 768px) {
        width: 130px;
      }

      @media (min-width: 992px) {
        width: 130px;
      }
    }
  }
  .rightPanel {
    position: fixed;
    top: 12px;
    right: -240px;
    background-color: rgba(71,71,71,.93);
    z-index: 2;
    padding: 15px 20px;
    transition: 0.4s;

    @include media(small) {
      padding: 15px 20px;
      top: 15px;
    }
    @include media(large) {
      top: 35px;
    }
    @include media(xlarge) {
      padding: 20px 30px;
    }
    
  }

  &.active {
    .navbar-brand {
      left: 0;
    }
    .rightPanel {
      right: 0;
    }
  }
}
