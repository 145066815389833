.newsHome{
    background-color:#919191;
    color:#fff;
    padding-top:50px;
    padding-bottom:70px;

    .titleSection{
        color:#fff;
    }

    .lineSeparator{
        background-color:#fff;
    }

    .subTitle{
        color:#fff;
    }

    .primaryBtn{
        background-color:transparent;
        border-color:#fff;
        color:#fff;
    }
}


.newsSlider{
    margin-top:20px;

    .item{
        article{
            padding:0 30px;

            @media(min-width:992px){
                padding: 0 100px;
            }
        
            h5.date{
                font-size: 14px;
                text-transform: uppercase;
                font-weight: 500;
                letter-spacing: 1px;
                padding:20px 0 25px 0;
            }

            h5.title{
                font-size:24px;
                @include goudyFont;
                color:#dedede;
            }
            
            .text{
                padding-top:25px;
                padding-bottom:25px;
                p{
                    font-size:14px;
                }
            }

            .photoBox{
                text-align: center;
                @media(max-width:768px){
                    margin:25px 0;
                }
                img{
                    width:auto;
                    margin:0 auto;
                }
            }
        }

    }

}


.newsSlider.owl-theme .owl-nav {
    width: 100%;
    max-width: 1630px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: space-between;

    height: 0;
    margin-top: -40px;
  }

  .newsSlider.owl-carousel .owl-nav button.owl-prev,
  .newsSlider.owl-carousel .owl-nav button.owl-next {
  width: 15px;
  height: 17px;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin: 20px 5px;

  @media (min-width: 576px) {
    margin: 20px;
  }

  @media (min-width: 992px) {
    width: 24px;
    height: 44px;
  }

  &:hover {
    background-color: transparent;
  }
}

.newsSlider.owl-carousel .owl-nav button.owl-prev {
  background-image: url("../images/owl-left-gray.png");
  margin-left:-50px;
}

.newsSlider.owl-carousel .owl-nav button.owl-next {
  background-image: url("../images/owl-right-gray.png");
  margin-right:-50px;
}