.opinions-slider {
  &-item {
    padding: 20px 0;

    @include media(large) {
      padding: 20px 0;
    }

    &__text {
      text-align: justify;
      padding: 15px 0;
      max-width: 930px;
      margin: 0 auto;

      p {
        @include media(xlarge) {
          font-size: 16px;
        }
      }
    }

    &__author {
      padding-top: 15px;
      padding-bottom: 20px;
    }
  }

  .slick-dots li,
  .slick-dots li button {
    width: 8px;
  }

  .slick-dots li button:before {
    color: #878787;
    font-size: 45px;
  }

  /* the slides */
  .slick-slide {
    margin: 0 20px;
  }
  /* the parent */
  .slick-list {
    margin: 0 -20px;
  }

  //   .slick-arrow {
  //     width: 30px;
  //     height: 30px;
  //     background-size: cover;
  //     z-index: 22;
  //     font-size: 0 !important;
  //     opacity: 0.9;
  //     transition: 0.2s;

  //     @include media(large) {
  //       width: 60px;
  //       height: 60px;
  //     }

  //     &:hover {
  //       opacity: 1;
  //     }

  //     &:before {
  //       content: "";
  //     }
  //   }

  //   .slick-prev {
  //     background-image: url("../images/opinions_prev.jpg");
  //     left: 5px;
  //   }

  //   .slick-next {
  //     background-image: url("../images/opinions_next.png");
  //     right: 5px;
  //   }
}
