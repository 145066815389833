.tableBox{
    margin:0 auto;
    overflow-x: scroll;
    font-size:14px;
    @media(min-width:992px){
        font-size:15px;
        overflow-x: hidden;
    }

    @media(min-width:1400px){
        font-size:17px;
        
    }

    .table{
        text-align: center;
        color:$darkBlue;
        thead{
            th{
                vertical-align: middle;

                
                p{
                    min-height:50px;
                    padding-top:8px;
                    font-size:13px;

                    @media(min-width:992px){
                        font-size:14px;
                    }
                }
            }
        }
        .title{
            font-weight: 600;
            text-align: left;;
        }

        tbody{

            th{
                vertical-align: middle;
            }

            td{
                vertical-align: middle ;
                font-size:15px;

                @include media(small){
                    font-size:16px;
                }
                @include media(medium){
                    font-size:17px;
                }
                @include media(large){
                    font-size:18px;
                }
            }

            .full{
                background-color:$heavenly;
                text-transform: uppercase;
                padding-top:19px;
                padding-bottom:20px;
            }

            .leftTitle{
                text-align: left;
                @include headFontLight;
                

                
            }

            .iconWrap{
                width:50px;
                text-align:right;
                margin-left: auto;
               
                padding-left:8px;

                @media(min-width:992px){
                    width:70px;
                    margin-right: 20px;
                }

                img{
                    width:50px;

                    @media(min-width:992px){
                        width:70px;
                    }
                }
            }
        }
    }
}


.table-bordered, .table-bordered td, .table-bordered th{
    border-color:$darkBlue;
}

.table thead th {
    border-bottom: 1px solid $darkBlue;
}

.table td, .table th{
    padding: .99rem .5rem;

    @include media(small){
        padding: .99rem .75rem;
    }
}