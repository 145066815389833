.rooms {
  background-color: #efece8;
  position: relative;
  padding-top: 40px;
  background-image: url("../images/room_home_bg_element.jpg");
  background-repeat: no-repeat;
  background-position: 100% 99.5%;

  @media (min-width: 768px) {
    padding-top: 50px;
  }

  .title {
    margin: 0 auto 40px auto;
    width: 270px;
    height: 270px;
    text-align: center;
    background-color: #fff;
    color: #000;
    transition: 0.3s;

    @media (min-width: 768px) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 12;
      margin: 0;
    }

    @media (min-width: 992px) {
      width: 300px;
      height: 300px;
    }

    @include flex-center;

    @include on-event {
      text-decoration: none;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }

    h3 {
      font-size: 50px;
      width: 100%;
      height: 100%;
      position: relative;
      @include flex-center;
      overflow: hidden;

      @media (min-width: 992px) {
        font-size: 50px;
      }

      &::before {
        position: absolute;
        top: 0;
        left: -75%;
        z-index: 2;
        display: block;
        content: "";
        width: 50%;
        height: 100%;
        background: -webkit-linear-gradient(
          left,
          rgba(229, 217, 198, 0) 0%,
          rgba(229, 217, 198, 0.3) 100%
        );
        background: linear-gradient(
          to right,
          rgba(229, 217, 198, 0) 0%,
          rgba(229, 217, 198, 0.3) 100%
        );
        -webkit-transform: skewX(-25deg);
        transform: skewX(-25deg);
      }

      @include on-event {
        &::before {
          animation: shine 0.85s;
        }
      }
    }
  }

  @keyframes shine {
    100% {
      left: 125%;
    }
  }

  .item {
    width: 100%;
    overflow: hidden;
    position: relative;

    button {
      z-index: 10;
      position: absolute;
      transition: 0.45s;
    }

    &:hover {
      img {
        transform: scale(1.07);
      }
    }

    &--1 {
      height: 100%;
      min-height: 350px;

      @media (min-width: 768px) {
        min-height: 600px;
        height: 90vh;
      }

      @include on-event {
        button {
          bottom: 50px;
        }
      }

      button {
        bottom: 50px;
        left: 20px;

        @media (min-width: 768px) {
          bottom: -100px;
        }
      }
    }
    &--2 {
      height: 100%;
      min-height: 350px;

      @media (min-width: 768px) {
        min-height: inherit;
      }

      @include on-event {
        button {
          top: 30px;
        }
      }
      button {
        top: 30px;
        left: 20px;

        @media (min-width: 768px) {
          top: -100px;
        }
      }
    }
    &--3 {
      height: 100%;
      min-height: 350px;

      @media (min-width: 768px) {
        min-height: inherit;
      }

      @include on-event {
        button {
          bottom: 50px;
        }
      }

      button {
        bottom: 50px;
        left: 20px;

        @media (min-width: 768px) {
          bottom: -100px;
        }
      }
    }

    img {
      min-height: 50%;
      transition: 0.45s;
    }
  }
}
