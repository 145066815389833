header{
    .rightPanel{
        .socialIcons{
            .darkSocialIcon{
                display:none;
            }
        }
    }
}

header.headerSimple{
    .rightPanel{
        .socialIcons{

            img{
                display:none;
            }

            .darkSocialIcon{
                display:inline-block;
            }
        }
    }
}