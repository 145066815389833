.location {
  overflow: hidden;
  padding-top: 50px;
  padding-bottom: 0;

  @media (min-width: 768px) {
    padding-top: 60px;
    padding-bottom: 0;
  }

  @media (min-width: 1400px) {
    padding-top: 100px;
    padding-bottom: 0;
  }

  .titleSection {
    padding-bottom: 20px;
    @media (min-width: 576px) {
      padding-bottom: 25px;
    }
  }
}
