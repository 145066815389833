.pageRooms {

  .offerRooms{
    .item:nth-child(even){
        .photoWrap{
          @media(min-width:768px){
            order:2;
          }
        }
    }
  }

  .offer {
    padding-bottom: 0;
  }

  .tryApartments.rooms {
    background-image: unset;
    padding-bottom: 50px;
    @include media(xlarge) {
      padding-bottom: 75px;
    }
    .item {
      height: 50vh;
      min-height: 500px;
      max-height: 600px;
      overflow: hidden;

      & > a {
        display: block;
        z-index: 2;
        position: absolute;
        width: 100%;
        height: 100%;
      }

      &--1,
      &--2 {
        @include buttonTopToDown;
      }

      &--3,
      &--4 {
        @include buttonDownToTop;
      }

      .title {
        width: 200px;
        height: 200px;
        @include media(medium) {
          width: 210px;
          height: 210px;
        }

        @include media(xlarge) {
          width: 220px;
          height: 220px;
        }

        @include positionCenter;
        h3 {
          color: #000;
          font-size: 35px;

          @include media(medium) {
            font-size: 40px;
          }

          @include media(xlarge) {
            font-size: 50px;
          }
        }
      }
    }
  }
}
