.formContact{
    background-color:#e6e6e6;
    padding:30px;

    input[type="text"],
    input[type="email"],
    input[type="tel"],
    textarea{
        font-size: 13px;
        border-radius: 0;
        transition: .3s;
        padding-top: 11px;
        padding-bottom: 11px;
        height: auto;
        font-family: "Montserrat", sans-serif;
        font-weight: 300;

        &:focus{
            padding-left:15px;
            border-color:$orange;
            box-shadow: 0 0 0 0.2rem rgba(236, 102, 21, .25);
        }
    }

    .primaryBtn{
        min-width:130px;
    }

    .form-group {
        margin-bottom: .7rem;
    }

    .agree{
        font-size:11px;
    }

    span.wpcf7-not-valid-tip{
        color: #f00;
        font-size: 12px;
    }

    span.wpcf7-list-item{
        margin-left:0;
        margin-right: 5px;
    }
    label{
        cursor:pointer;
    }
}

div.wpcf7-validation-errors, 
div.wpcf7-acceptance-missing{
    font-size:13px;
}




