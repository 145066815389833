.specialScroll{
    padding-top:0;
    padding-bottom:0;
    overflow:unset;

    .item{
        height:100vh;
        width:100%;
        display:flex;
        justify-content: center;
        align-items:center;
        
        &:nth-child(even){
            background-color:#e8e8e8;
        }
        
        .content{
            width: 500px;
            margin-left:auto;

            .titleSection{
                border-bottom:2px solid #cccccc;
                padding-bottom:7px;
            }

            .subTitle{
                padding-top:5px;
                padding-bottom:70px;
            }

            .text{
                max-width: 428px;
                margin:60px auto;
                text-align: right;
                letter-spacing: 1px;
                line-height: 28px;
                padding-right: 83px;
            }
        }
    }
    .photoWrap{
        height:100%;
        position:relative;
        .photo{
            height:100vh;
            width:100%;
            background-size:cover;
            background-position: center;
            position:sticky;
            top:0;
            background-image:url('../images/scroll1.jpg');
        }
    }
}