.ourHotels{
    background-color:#fff;
    padding-top:0;
    padding-bottom:0;

    .nav{
        border:0;
        overflow:hidden;
        
        li{
            width:50%;
            
            a{
                
                transition:.2s;
                display:block;
                padding:15px 20px;
                border:2px solid transparent;
                text-align: center;
                position:relative;

                &:not(.active){
                    background-color:#f1f1f1;
                }

                @include on-event{
                    text-decoration:none
                }
            }

            &:nth-child(1){
                a{
                    &:not(.active){
                        
                    }

                    &:after{
                        display:block;
                        content:'';
                        width: 30px;
                        height:100%;
                        position: absolute;
                        top: -2px;
                        right: -38px;
                        width: 0;
                        height: 0;
                        border-color: #f1f1f1 transparent transparent;
                        border-style: solid;
                        border-width: 103px 35px 0;
                        z-index: 5;
                    }
                }
            }

            &:nth-child(2){
                a{
                    &:not(.active){
                        
                    }
                }
            }
            

            .titleSection{
                color:#333333;
            }

            h5{
                text-transform:uppercase;
                font-size:14px;
                color:#333333;
            }
        }
    }

    .tab-content{
        padding:30px 20px;

        a{
            color:$orange;
        }

        .item{
            padding:3px 5px;
            text-align: center;

            p{
                color:#333333;
                font-size:14px;

                @media(min-width:1400px){
                    font-size:14px;
                }
            }

            &.big{
                p{
                    font-size:15px;

                    @media(min-width:1400px){
                        font-size:16px
                    }
                }
            }
        }

        .wrap{
            max-width: 430px;
            margin:0 auto;
        }

        #ourGroup{
            .item{
                p{
                    margin-top:6px;
                }
            }
        }

    }

    
}