.newsletter {

  color: #333333;
  position: relative;
  padding-top: 0;
  padding-bottom: 20px;

  display:flex;
  justify-content: center;
  align-items:center;

  span.wpcf7-list-item{
    margin-left:0;
  }

  h5 {
    font-family: GoudyOlStPL,sans-serif;
    padding-bottom: 20px;
    font-size: 23px;
  }

  p {
    font-size: 11px;

    @media (min-width: 576px) {
      font-size: 12px;
    }
  }

  label {
    font-size: 10px;
    line-height: 13px;
    color: #333333;
    padding-left: 0;
    cursor:pointer;
    align-items: center;
  }

  .safe {
    color: #333333;
    font-size: 12px;
    padding-top: 14px;
    @media (min-width: 576px) {
      font-size: 14px;
    }
  }

  input[type="text"],
  input[type="email"] {
    border-radius: 0 !important;
    border: 1px solid #848484;
    color:#848484;
    background-color:#fff;
    height: auto;
    padding: 10px 15px;
    transition: 0.3s;
    margin-bottom: 5px;
    font-size: 12px;
    margin-top: 5px;
    margin-bottom: 5px;
    font-weight:300;
    text-transform:lowercase;
    font-family: "Montserrat", sans-serif;

    @media (min-width: 992px) {
      font-size: 13px;
    }

    &:focus {
      padding-left: 10px;
      box-shadow: none !important;
      border-color: #333333;
    }
  }

  div.wpcf7 .ajax-loader {
    position: absolute;
    margin-top: 8px;
  }

  .wpcf7-form-control-wrap {
    position: static;
    
    width: 20px;


    span.wpcf7-not-valid-tip {
      width: 100%;
      font-size: 12px;

      @media (min-width: 576px) {
        //position: absolute;
      }
    }
  }

  .wpcf7-response-output.wpcf7-display-none.wpcf7-validation-errors,
  div.wpcf7-mail-sent-ng,
  div.wpcf7-aborted {
    border: none;
    color: #f00;
    font-size: 13px;
    text-align: center;
    margin-top: 12px;
    margin-bottom: 0px;
  }

  div.wpcf7-mail-sent-ok {
    border: none !important;
    color: #398f14 !important;
    text-align: center !important;
    font-weight: bold !important;
    margin: 10px 0px !important;
  }

  .btnBox {
    input[type="checkbox"] {
      opacity: 0;
      width:0;
      height:0;
      position: absolute;
    }
  }

  .formWrap {
    @include flex-center;
    padding: 5px 0;
    margin-top: 30px;
    max-width:360px;

    h5 {
      color: #333333;
      font-size: 30px;
    }

    input[type="submit"] {
      color:#333333;
    }
  }

  .agree {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
  }

  div.wpcf7-response-output{
    font-size: 13px;
  }

  .checkAcceptRodo{
    margin-left:0;
    margin-right: 5px;
  }

}
