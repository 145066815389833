@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans-Light.woff2') format('woff2'),
      url('../fonts/OpenSans-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'GoudyOlStPL';
  src: url('../fonts/GoudyOlStPL-BoldItalic.woff2') format('woff2'),
      url('../fonts/GoudyOlStPL-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'GoudyOlStPL';
  src: url('../fonts/GoudyOlStPL-Italic.woff2') format('woff2'),
      url('../fonts/GoudyOlStPL-Italic.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Light.woff2') format('woff2'),
      url('../fonts/Montserrat-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Regular.woff2') format('woff2'),
      url('../fonts/Montserrat-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

//font-family: 'Lato', sans-serif;
//font-family: 'Amatic SC', cursive;
//font-family: 'Shadows Into Light Two', cursive;

body {
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
}

h5 {
  font-size: 38px;
}
