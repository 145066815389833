.twoRowsSlider {
  .item {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    // background-image: url("../images/dom_homepage.jpg");
    width: 400px;
    height: 300px;
    position: relative;
    overflow: hidden;
    border-left: 8px solid #fff;
    border-right: 8px solid #fff;
    border-top: 6px solid #fff;
    border-left: 6px solid #fff;

    .psuedo-background-img {
      width: 100%;
      height: 100%;
    }
  }

  .slick-prev {
    background-image: url("../images/slick-prev-arrow.png");
    width: 20px;
    height: 32px;
    left: -50px;

    @include on-event {
      background-image: url("../images/slick-prev-arrow.png");
    }

    &:before {
      content: unset;
    }
  }

  .slick-next {
    background-image: url("../images/slick-next-arrow.png");
    width: 20px;
    height: 32px;
    right: -40px;

    @include on-event {
      background-image: url("../images/slick-next-arrow.png");
    }

    &:before {
      content: unset;
    }
  }
}
