.mainSlider {

  @media(min-width:1050px){
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    //height: 100vh;
    z-index: 1;
  }



  padding-top: 0 !important;
  padding-bottom: 0;
  min-height: 100vh;

  .item {
    min-height: 100vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;

    @media (min-width: 576px) {
      height: 100vh;
    }

    @media (min-width: 768px) {
      height: 100vh;
    }

    @media (min-width: 992px) {
      height: 100vh;
    }

    @include overlay(#000, 0.1);

    .textBox {
      position: absolute;
      top: 40%;
      width: 100%;
      padding-left: 45px;
      padding-right: 45px;

      .mainText {
        color: #fff;

        font-size: 38px;
        text-shadow: 5px 5px 5px rgba(0, 0, 0, 0.4);
        text-transform: inherit;

        @media (min-width: 576px) {
          font-size: 40px;
        }
        @media (min-width: 768px) {
          font-size: 45px;
        }
        @media (min-width: 992px) {
          font-size: 50px;
        }
        @media (min-width: 1200px) {
          font-size: 50px;
        }

        @media (min-width: 1400px) {
          font-size: 60px;
        }

        @media (min-width: 1600px) {
          font-size: 72px;
        }
      }

      .secondText {
        color: #fff;

        text-shadow: 5px 5px 5px rgba(0, 0, 0, 0.4);
        padding-top: 5px;

        &,
        & > * {
          font-size: 23px;
          @media (min-width: 576px) {
            font-size: 26px;
          }
          @media (min-width: 768px) {
            font-size: 28px;
          }
          @media (min-width: 992px) {
            font-size: 30px;
          }
          @media (min-width: 1200px) {
            font-size: 30px;
          }
          @media (min-width: 1400px) {
            font-size: 36px;
          }
        }
      }

      .btnBox {
        padding-top: 5px;
        @media (min-width: 768px) {
          padding-top: 20px;
        }
      }
    }

    display: none;

    &.onMobile {
      display: block;

      @media (min-width: 768px) {
        display: none;
      }
    }

    &.onDesktop {
      display: none;

      @media (min-width: 768px) {
        display: block;
      }
    }

    &.onMobile.onDesktop {
      display: block;
    }
  }

  .owl-theme .owl-nav {
    width: 100%;
    max-width: 1630px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: space-between;

    height: 0;
    margin-top: -40px;
  }

  .owl-theme .owl-dots {
    position: absolute;
    bottom: 10px;
    text-align: center;
    margin: 0 auto;
    left: 50%;
    transform: translate(-50%);
  }

  .owl-theme .owl-dots .owl-dot span {
    margin: 5px 4px;
    background-color: #fff;
  }

  .owl-theme .owl-dots .owl-dot.active span,
  .owl-theme .owl-dots .owl-dot:hover span {
    background-color: #d6d6d6;
  }

  .owl-carousel .owl-nav button.owl-prev,
  .owl-carousel .owl-nav button.owl-next {
    width: 15px;
    height: 17px;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin: 20px 5px;

    @media (min-width: 576px) {
      margin: 20px;
    }

    @media (min-width: 992px) {
      width: 20px;
      height: 32px;
    }

    &:hover {
      background-color: transparent;
    }
  }

  .owl-carousel .owl-nav button.owl-prev {
    background-image: url("../images/owl-left.png");
  }

  .owl-carousel .owl-nav button.owl-next {
    background-image: url("../images/owl-right.png");
  }
}
