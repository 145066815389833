body {
  // opacity:0;
  transition: 0.1s;
}

@media(max-width:576px){
  section,.reservationPanelWrap{
    overflow-x: hidden !important;
  }
}

main.overSlider{
    
    position: relative;
    z-index: 10;
    background-color:#fff;

    @media(min-width:1050px){
      margin-top: 100vh;
    }
}

body.fadeIn {
  opacity: 1;
}

.page.noSlider {
  margin-top: 120px;

  @media (min-width: 768px) {
    margin-top:165px;
  }

  @media(min-width:992px){
    margin-top: 180px;
  }
}

.titleBox{
  font-family: 'Montserrat';
  font-weight: bold;
  font-size:15px;
  letter-spacing: .7px;
}

.contentTextWrap .textBox{
  line-height: 27px;
}

input.border-danger,
select.border-danger{
  border-color:#dc3545 !important;
  border:1px solid #dc3545 !important;
}

.p-relative {
  position: relative;
}

.mainContainer {
  max-width: 1400px;
  margin: 0 auto;

  @media (min-width: 992px) and (max-width: 1199px) {
    padding-left: 50px;
    padding-right: 50px;
  }

  @media (min-width: 1200px) {
    padding-left: 60px;
    padding-right: 60px;
  }

  &--full {
    max-width: inherit;
    width: 100%;
    max-width: 1700px;
  }
}

.bold {
}

a {
  color: $darkBlue;
}

/* BACKGROUND */

.bgPrimary {
  background-color: #efece8;
}

.darkBlueBg {
  background-color: $darkBlue;
}

p {
  font-size: 15px;
  font-weight: 300;
}

@media (min-width: 768px) {
  p {
    font-size: 16px;
  }
}

@media (min-width: 1200px) {
  p {
    font-size: 17px;
  }
}

.slide {
  width: 100%;
  height: 100%;
  position: relative;
}

.psuedo-background-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.orangeColor{
  color:$orange;

  @include on-event{
    color:$orange;
  }
}

.lineSeparator{
  width: 100%;
  height:1px;
  background-color:#888888;
  margin-top: 9px;
  margin-bottom: 7px;
}

.subTitle{
  color:#333333;
  text-transform:uppercase;
  font-size:16px;
}

.titleSection {
  color: #333333;
  font-size: 25px;
  font-family:"GoudyOlStPL", sans-serif;

  @include media(xsmall) {
    font-size: 26px;
  }

  @include media(small) {
    font-size: 28px;
  }

  @include media(medium) {
    font-size: 30px;
  }

  @include media(large) {
    font-size: 32px;
  }

  @include media(xlarge) {
    font-size: 34px;
  }

  &--medium {
    font-size: 23px;
    @include media(xsmall) {
      font-size: 24px;
    }
  
    @include media(small) {
      font-size: 26px;
    }
  
    @include media(medium) {
      font-size: 28px;
    }
  
    @include media(large) {
      font-size: 30px;
    }
  
    @include media(xlarge) {
      font-size: 32px;
    }
  }

  &--small {
    font-family:"GoudyOlStPL", sans-serif;
    font-size: 21px;

    @include media(xsmall) {
      font-size: 22px;
    }

    @include media(small) {
      font-size: 23px;
    }

    @include media(medium) {
      font-size: 24px;
    }

    @include media(large) {
      font-size: 26px;
    }

    @include media(xxlarge) {
      font-size: 28px;
    }
  }

  &--xsmall {
    font-size: 20px;

    @include media(xsmall) {
      font-size: 20px;
    }

    @include media(medium) {
      font-size: 22px;
    }
  }

  &--black {
    color: black;
  }

  &--bold{
    font-weight:bold;
  }
}

.owl-item.active.center {
  transform: scale(1.1, 1.2);
}

.owl-carousel .owl-nav.disabled {
  display: none !important;
}

div.wpcf7 .wpcf7-submit:disabled {
  opacity: 0.7;
}


a{
  color:$orange;

  @include on-event{
    color:$orange;
  }
}


button:focus{
  outline:none;
}


