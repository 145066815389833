.headerBottom {
  background-color:rgba(71, 71, 71, .93);
  padding-left:15px;
  padding-right: 15px;
  .navbar {
    padding:0;
    margin-top:15px;
    .navbar-nav {
      width: 100%;
      
      justify-content: space-between;
      flex-direction: row;

      display: none;

      

      @media (min-width: 768px) {
        display: flex;
      }

      .nav-item {
        
        .nav-link {
          padding: 6px 10px;
          //margin: 0px 25px;
          color: #fff;
          text-transform: uppercase;
          font-size: 13px;
          max-width: 240px;
          text-align: center;
          display:flex;
          align-items:center;
          height:100%;
          transition:.3s;
          line-height: 19px;
          font-family: "Montserrat", sans-serif;
          font-weight: 300;
          //background-color:rgba(71, 71, 71, .7);
          &[data-toggle="dropdown"] {
            padding-right: 25px !important;
          }

          @include on-event{
            background-color:rgba(71, 71, 71, .8);
          }

          @media(min-width:992px){
            font-size: 14px;
          }

          @media(min-width:1200px){
            font-size: 14px;
          }

          @media(min-width:1350px){
            padding: 6px 15px;
          }

          @media(min-width:1400px){
            font-size: 14px;
          }

          @media(min-width:1500px){
            padding: 5px 20px;

          }

        }

        .hvr-underline-from-center:before {
          background: #fff;
          height: 1px;
        }
      }
    }
  }
}
