.hotelsList{

    @media(min-width:1050px){
        //margin-top: 100vh;
        position: relative;
        z-index: 10;
    }
    

    display:flex;
    flex-wrap: wrap;
    justify-content: center;
    background-color:#474747;

    .item{
        width: 100%;
        margin: 1%;
        

        @media(min-width:576px){
            width: 48%;
            margin: 1%;
        }

        @media(min-width:768px){
            width: 32%;
            margin: .5%;
        }

        @media(min-width:992px){
            width: 24%;
            margin: .5%;
        }

        @media(min-width:1200px){
            width: 19%;
            margin: .5%;
        }

        

        img{
            transition:.3s;

            
        }

        
        &:hover{
            z-index: 222;

            img{
                &:hover{
                    cursor:pointer;
                    transform: scale(1.17);
                    position: absolute;
                    z-index: 222222222;
                    box-shadow: 0 20px 50px rgba(236, 102, 21, .7);
                }
            }
        }

        &:nth-child(odd){
            &:hover{
                img{
                    &:hover{
                        cursor:pointer;
                        transform: scale(1.17);
                        position: absolute;
                        z-index: 222222222;
                    }
                }
            }
        }
        
    }
}