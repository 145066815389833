$yellow: #e35205;

$lightBlue: #0f3959;
$heavenly: #0f3959;

$lightGray: #e7e6e5;

$blue: #0f3959;

//new
$darkBlue: #0f3959;


//new
$darkGrey: #474747;
$orange: #ec6615;
