.modal{
    //color:$darkBlue;
    
    .modal-title{
        font-size:20px;
      
        @media(min-width:992px){
          font-size:25px;
        }
      
        @media(min-width:1400px){
          font-size:30px;
        }
      
      }

    .modal-body{
        font-size:13px;

        @media(min-width:576px){
            font-size:15px;
        }

        h5{
            font-weight:bold;
            font-size:16px;
            @media(min-width:576px){
                font-size:18px; 
            }
        }
    }

    .btnBox,
    .modal-footer{
        .yellowSmallBtn,
        .blueSmallBtn{
            background-color:$orange !important;
            color:#fff !important;

            @include on-event{
                background-color:$orange !important;
                color:#fff !important;
            }
        }

        .hvr-ripple-out:before{
            border-color:$orange !important;
        }
    }

    
}