.burgerIcon {
  max-width:30px;
  width:30px;
  height:23px;

  &--white{
    display:block;
  }

  &--gray{
    display:none;
  }

  @media(min-width:768px){
    max-width: 40px;
    width:40px;
    height:30px;
  }
  

  &:hover {
    cursor: pointer;
  }
}

.headerSimple{
  .burgerIcon{
    &--white{
      display:none;
    }
  
    &--gray{
      display:block;
    }
  }
}