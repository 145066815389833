.upArrow{
    display: none;
    position: fixed;
    right: 25px;
    bottom: 15px;
    cursor: pointer;
    z-index: 50;

    @media(min-width:1050px){
        bottom: 100px;
    }
}