.pageConference{
    .know{
        background-color:#fff;
        padding-bottom:0;
        .iconsWrap{
            .item{
        
                height:200px;      
                background-color:$blue;
                padding:10px;
                display:flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                
                &__icon-box{
                    height:120px;
                    width:100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                &__icon{
                    height:auto;
                    max-width: 100px;
                    max-height: 100px;
                }
        
                &__title{
                    text-transform: inherit;
                    color:#fff;
                    font-size:15px;
                    text-align: center;
                    padding:15px 0 3px 0;
                }
        
                &--1{
                    background-color:$darkBlue;

                    .item__icon{
                        margin-top:20px;
                    }
                }
        
                &--2{
                    background-color:$heavenly;

                    .item__icon{
                        height:auto;
                        max-width: 100px;
                        max-height: 100px;
                        margin-top:20px;
                    }

                    .item__title{
                        color:$darkBlue;
                    }
                }
        
                &--3{
                    background-color:$lightBlue;
                    
                    .item__icon{
                        height:auto;
                        max-width: 100px;
                        max-height: 100px;
                    }
        
                    
                }
        
                &--4{
                    background-color:$blue;
                }
        
                &--5{
                    
                }
        
                &--6{
                    background-color:$darkBlue;
                }
        
            }
        }
    }
}