.imageSection {
  width: 100%;
  padding: 0;
  height: 400px;
  background-image: url("../images/ptak.jpg");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  overflow: hidden;

  @media (min-width: 768px) {
    background-attachment: fixed;
  }

  &--goTop {
    margin-top: -60px;
  }

  @media (min-width: 992px) {
    height: 450px;
  }

  @media (min-width: 1400px) {
    height: 550px;
  }

  @media (min-width: 1600px) {
    height: 600px;
  }
}
