.youAboutUs {
  padding-top: 0;
  padding-bottom: 0;

  @media (min-width: 768px) {
    padding-bottom: 50px;
  }

  @media (min-width: 1400px) {
    padding-bottom: 100px;
  }
}
