.youAbousUsPage {
  .opinionsWrap {
    img {
      @media (min-width: 1200px) {
        margin-right: -75px;
        margin-left: auto;
        display: block;
      }

      @media (min-width: 1400px) {
        margin-right: -100px;
        margin-left: auto;
        display: block;
      }
    }
  }
}
